import { LEARNING_CENTRE } from "../actions/utilities";

const INITIAL_STATE = {
  getAllVideosLoading: false,
  getAllVideosSuccess: false,
  getAllVideosFailure: false,
  getAllVideosError: null,
  allVideos: [],
  allVideosCount: 0,

  addNewVideoLoading: false,
  addNewVideoSuccess: false,
  addNewVideoFailure: false,
  addNewVideoError: null,
  addNewVideo: null,

  deleteVideoLoading: false,
  deleteVideoSuccess: false,
  deleteVideoFailure: false,
  deleteVideoError: null,
  deleteVideo: null,
};

export const learningCentreReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LEARNING_CENTRE.GET_VIDEOS_LEARNING_CENTRE_REQUEST:
      return {
        ...state,
        getAllVideosLoading: true,
        getAllVideosSuccess: false,
        getAllVideosFailure: false,
        getAllVideosError: null,
        allVideos: [],
        allVideosCount: 0,
      };
    case LEARNING_CENTRE.GET_VIDEOS_LEARNING_CENTRE_FAILURE:
      return {
        ...state,
        getAllVideosLoading: false,
        getAllVideosSuccess: false,
        getAllVideosFailure: true,
        getAllVideosError: action.payload,
      };
    case LEARNING_CENTRE.GET_VIDEOS_LEARNING_CENTRE_SUCCESS:
      return {
        ...state,
        getAllVideosLoading: false,
        getAllVideosSuccess: true,
        getAllVideosFailure: false,
        getAllVideosError: null,
        allVideos: action.payload,
        allVideosCount: action.info.count,
      };
    case LEARNING_CENTRE.ADD_VIDEO_LEARNING_CENTRE_REQUEST:
      return {
        ...state,
        addNewVideoLoading: true,
        addNewVideoSuccess: false,
        addNewVideoFailure: false,
        addNewVideoError: null,
        addNewVideo: null,
      };
    case LEARNING_CENTRE.ADD_VIDEO_LEARNING_CENTRE_FAILURE:
      return {
        ...state,
        addNewVideoLoading: false,
        addNewVideoSuccess: false,
        addNewVideoFailure: true,
        addNewVideoError: action.payload,
      };
    case LEARNING_CENTRE.ADD_VIDEO_LEARNING_CENTRE_SUCCESS:
      return {
        ...state,
        addNewVideoLoading: false,
        addNewVideoSuccess: true,
        addNewVideoFailure: false,
        addNewVideoError: null,
        addNewVideo: action.payload,
      };
    case LEARNING_CENTRE.DELETE_VIDEO_LEARNING_CENTRE_REQUEST:
      return {
        ...state,
        deleteVideoLoading: true,
        deleteVideoSuccess: false,
        deleteVideoFailure: false,
        deleteVideoError: null,
        deleteVideo: null,
      };
    case LEARNING_CENTRE.DELETE_VIDEO_LEARNING_CENTRE_FAILURE:
      return {
        ...state,
        deleteVideoLoading: false,
        deleteVideoSuccess: false,
        deleteVideoFailure: true,
        deleteVideoError: action.payload,
      };
    case LEARNING_CENTRE.DELETE_VIDEO_LEARNING_CENTRE_SUCCESS:
      return {
        ...state,
        deleteVideoLoading: false,
        deleteVideoSuccess: true,
        deleteVideoFailure: false,
        deleteVideoError: null,
        deleteVideo: action.payload,
      };

    default:
      return state;
  }
};
