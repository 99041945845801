import React, { useEffect, useState } from "react";
import { Button, Spinner, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getAllClinicsListForSelectAction } from "../../redux/actions";
import { format } from "date-fns";

import {
  getCurrentMonthInNumber,
  getCurrentMonthName,
  getCurrentYear,
  handleFilters,
  OPTION,
} from "./";

export const ExportModal = ({
  title,
  show,
  handleClose,
  isLoading,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const clinics = useSelector((state) => state.clinicsReducer);

  const intialState = {
    filters: {
      Month: getCurrentMonthInNumber(),
      Year: getCurrentYear(),
      AgeLimit: "",
      GenderId: "",
    },
    values: {
      Month: [
        {
          value: getCurrentMonthInNumber(),
          label: getCurrentMonthName(),
        },
      ],
      Year: {
        value: getCurrentYear(),
        label: getCurrentYear(),
      },
      AgeLimit: { value: "", label: "All Ages" },
      GenderId: { value: "", label: "All Genders" },
    },
  };

  const [stateFilters, setStateFilters] = useState(intialState);

  const config = {
    state: stateFilters,
    setState: setStateFilters,
  };

  const resetForm = () => setStateFilters(intialState);

  const handleSubmit = () => {
    let filters = {
      ...stateFilters.filters,
      Month: (stateFilters.values.Month || [])
        .map((item) => item.value)
        .toString(),
    };

    const fileName = !!stateFilters.values.ClinicId
      ? `${stateFilters.values.ClinicId.label}_${format(
          Date.now(),
          "yyyy-MM-dd"
        )}.xlsx`
      : `All Clinics_${format(Date.now(), "yyyy-MM-dd")}.xlsx`;

    const onSuccessFunc = () => {
      handleClose();
      resetForm();
    };

    onSubmit(filters, fileName, onSuccessFunc);
  };

  useEffect(() => {
    if (!clinics.clinicsListSelect.length > 0) {
      dispatch(getAllClinicsListForSelectAction());
    }
    //eslint-disable-next-line
  }, []);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12 pr-lg-0 mb-3">
            <Select
              options={OPTION.MONTH}
              className="d-inline-block w-100"
              placeholder="Month"
              isDisabled={isLoading}
              isClearable
              value={stateFilters.values.Month || ""}
              name="Month"
              onChange={(value, { name }) => {
                handleFilters(value, name, config);
              }}
              isMulti
            />
          </div>
          <div className="col-md-12 pr-lg-0 mb-3">
            <Select
              options={OPTION.YEAR}
              className="d-inline-block w-100"
              id="customSlct"
              placeholder="Year"
              isDisabled={isLoading}
              isClearable
              value={stateFilters.values.Year || ""}
              name="Year"
              onChange={(value, { name }) => handleFilters(value, name, config)}
            />
          </div>
          <div className="col-md-12 pr-lg-0 mb-3">
            <Select
              options={OPTION.AGE}
              className="d-inline-block w-100"
              placeholder="Age"
              isDisabled={isLoading}
              name="AgeLimit"
              value={stateFilters.values.AgeLimit || ""}
              onChange={(value, { name }) => handleFilters(value, name, config)}
            />
          </div>
          <div className="col-md-12 pr-lg-0 mb-3">
            <Select
              options={OPTION.GENDER}
              className="d-inline-block w-100"
              placeholder="Gender"
              name="GenderId"
              value={stateFilters.values.GenderId || ""}
              isDisabled={isLoading}
              onChange={(value, { name }) => handleFilters(value, name, config)}
            />
          </div>

          <div className="col-md-12 pr-lg-0 mb-3">
            <Select
              className="d-inline-block w-100"
              placeholder="Clinics"
              options={(clinics.clinicsListSelect || []).map((item) => {
                return { value: item.id, label: item.name };
              })}
              onChange={(value, { name }) => handleFilters(value, name, config)}
              name="ClinicId"
              value={stateFilters.values.ClinicId || ""}
              isClearable
              isLoading={clinics && clinics.getClinicListSelectLoading}
              isDisabled={isLoading}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          type="button"
          disabled={isLoading}
          onClick={() => handleSubmit()}
        >
          {isLoading === true && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"
            />
          )}
          Export
        </Button>
        <Button variant="secondary" disabled={isLoading} onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
