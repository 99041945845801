import { request, success, failure, DOCTOR_REQUEST } from "./utilities";
import {
  addEditDoctorApi,
  getAllDoctorListApi,
  getDoctorsForSelectApi,
  getSingleDoctorDetailsApi,
  setDoctorActiveApi,
} from "../api";

export function getAllDoctorList(filters) {
  return (dispatch) => {
    dispatch(request(DOCTOR_REQUEST.GET_DOCTOR_LIST_REQUEST));
    getAllDoctorListApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              DOCTOR_REQUEST.GET_DOCTOR_LIST_SUCCESS,
              response.data.data,
              {
                count: response.data.totalRecords,
              }
            )
          );
        } else {
          dispatch(
            failure(
              DOCTOR_REQUEST.GET_DOCTOR_LIST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(DOCTOR_REQUEST.GET_DOCTOR_LIST_FAILURE, error.message)
        );
      }
    );
  };
}

export function getSingleDoctorDetails(doctorId) {
  return (dispatch) => {
    dispatch(request(DOCTOR_REQUEST.GET_SINGLE_DOCTOR_DETAILS_REQUEST));
    getSingleDoctorDetailsApi(doctorId).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              DOCTOR_REQUEST.GET_SINGLE_DOCTOR_DETAILS_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              DOCTOR_REQUEST.GET_SINGLE_DOCTOR_DETAILS_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            DOCTOR_REQUEST.GET_SINGLE_DOCTOR_DETAILS_FAILURE,
            error.message
          )
        );
      }
    );
  };
}

export function setDoctorActive(rec) {
  return (dispatch) => {
    dispatch(request(DOCTOR_REQUEST.SET_DOCTOR_ACTIVE_REQUEST));
    setDoctorActiveApi(rec).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(success(DOCTOR_REQUEST.SET_DOCTOR_ACTIVE_SUCCESS, rec));
        } else {
          dispatch(
            failure(
              DOCTOR_REQUEST.SET_DOCTOR_ACTIVE_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(DOCTOR_REQUEST.SET_DOCTOR_ACTIVE_FAILURE, error.message)
        );
      }
    );
  };
}

export function addEditDoctor(rec, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(DOCTOR_REQUEST.ADD_EDIT_DOCTOR_REQUEST));
    addEditDoctorApi(rec)
      .then(
        (response) => {
          if (response.data.success === true) {
            onSuccess = true;

            dispatch(success(DOCTOR_REQUEST.ADD_EDIT_DOCTOR_SUCCESS, rec));
          } else {
            onFailure = true;

            dispatch(
              failure(
                DOCTOR_REQUEST.ADD_EDIT_DOCTOR_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(DOCTOR_REQUEST.ADD_EDIT_DOCTOR_FAILURE, error.message)
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function getDoctorsSelectAction(filters) {
  return (dispatch) => {
    dispatch(request(DOCTOR_REQUEST.GET_DOCTORS_SELECT_REQUEST));
    getDoctorsForSelectApi(filters).then(
      (response) => {
        if (response.data.success) {
          dispatch(
            success(
              DOCTOR_REQUEST.GET_DOCTORS_SELECT_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              DOCTOR_REQUEST.GET_DOCTORS_SELECT_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(DOCTOR_REQUEST.GET_DOCTORS_SELECT_FAILURE, error.message)
        );
      }
    );
  };
}
