import React, { useMemo, useState, useEffect } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Topbar } from "./Topbar";
import { HeaderMenuWrapper } from "./header-menu/HeaderMenuWrapper";
import { AnimateLoading } from "../../../_partials/controls";
import Select from "react-select";
import { useHistory, useLocation } from "react-router-dom";
import { PATH } from "../../../../app/config";
import { reactSelectCustom, dashboardOption } from "../../../../app/constant";
import { useSelector } from "react-redux";

export function Header() {
  const [dashboard, setDashboard] = useState({});
  const { user } = useSelector((state) => state.auth);

  const uiService = useHtmlClassService();
  const history = useHistory();
  const location = useLocation();

  function adminsRights() {
    let roles = user && user.data && user.data.roles;
    const conditions = ["SuperAdmin"];
    let find = conditions.some((role) => roles.includes(role));
    if (find) return true;
    else return false;
  }

  function onlyAdminsRights() {
    let roles = user && user.data && user.data.roles;
    const conditions = ["Admin"];
    let find = conditions.some((role) => roles.includes(role));
    if (find) return true;
    else return false;
  }

  const handleOnChange = ({ value }) => {
    if (value) {
      if (value === 1) {
        return history.push(PATH.MAIN_DASHBOARD);
      }
      if (value === 2) {
        return history.push(PATH.IMMUNIZATION_DASHBOARD);
      }
      if (value === 3) {
        return history.push(PATH.ANC_DASHBOARD);
      }
    }
  };

  const handleDashboard = (current) => {
    let option = [...dashboardOption];

    if (current === PATH.IMMUNIZATION_DASHBOARD) {
      return setDashboard(option[1]);
    }
    if (current === PATH.ANC_DASHBOARD) {
      return setDashboard(option[2]);
    }

    return setDashboard(option[0]);
  };

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        "header.menu.self.display"
      ),
    };
  }, [uiService]);

  useEffect(() => {
    handleDashboard(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      {/*begin::Header*/}
      <div
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
      >
        {/*begin::Container*/}
        <div
          className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}
        >
          <AnimateLoading />
          {/*begin::Header Menu Wrapper*/}
          {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />}

          {adminsRights() && (
            <>
              {/* Add Dashboard Switch Button */}
              {(location.pathname === PATH.ANC_DASHBOARD ||
                location.pathname === PATH.IMMUNIZATION_DASHBOARD ||
                location.pathname === PATH.MAIN_DASHBOARD) && (
                <div
                  className="header-menu-wrapper header-menu-wrapper-left"
                  id="kt_header_menu_wrapper"
                >
                  <div
                    id="kt_header_menu"
                    className="header-menu header-menu-mobile align-items-center"
                  >
                    <Select
                      options={dashboardOption}
                      styles={reactSelectCustom}
                      className="w230"
                      onChange={(value) => handleOnChange(value)}
                      value={dashboard}
                    />
                  </div>
                </div>
              )}
              {/* Add Dashboard Switch Button */}
            </>
          )}

          {onlyAdminsRights() && (
            <>
              {/* Add Dashboard Switch Button */}
              {(location.pathname === PATH.IMMUNIZATION_DASHBOARD ||
                location.pathname === PATH.MAIN_DASHBOARD) && (
                <div
                  className="header-menu-wrapper header-menu-wrapper-left"
                  id="kt_header_menu_wrapper"
                >
                  <div
                    id="kt_header_menu"
                    className="header-menu header-menu-mobile align-items-center"
                  >
                    <Select
                      options={[
                        { value: 1, label: "Global Dashboard" },
                        { value: 2, label: "Immunization" },
                      ]}
                      styles={reactSelectCustom}
                      className="w230"
                      onChange={(value) => handleOnChange(value)}
                      value={dashboard}
                    />
                  </div>
                </div>
              )}
              {/* Add Dashboard Switch Button */}
            </>
          )}

          {!layoutProps.menuHeaderDisplay && <div />}

          {/*begin::Topbar*/}
          <Topbar />
          {/*end::Topbar*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Header*/}
    </>
  );
}
