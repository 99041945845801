/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import Select from "react-select";
import { PATH } from "../../../../../app/config";
import { dashboardOption } from "../../../../../app/constant";
import {
  FaClinicMedical,
  FaUserMd,
  FaVideo,
  FaHandHoldingHeart,
  FaBaby,
  FaHospitalUser,
  FaTachometerAlt,
  FaUserShield,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountAction } from "../../../../../redux/actions";

export function AsideMenuList({ layoutProps }) {
  const [dashboard, setDashboard] = useState({});
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const counts = useSelector((state) => state.mainDashboard);
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    if (!counts.allCountRecords.length > 0) {
      dispatch(getAllCountAction());
    }
    //eslint-disable-next-line
  }, []);

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const handleOnChange = ({ value }) => {
    if (value) {
      if (value === 1) {
        return history.push(PATH.MAIN_DASHBOARD);
      }
      if (value === 2) {
        return history.push(PATH.IMMUNIZATION_DASHBOARD);
      }
      if (value === 3) {
        return history.push(PATH.ANC_DASHBOARD);
      }
    }
  };

  function superAdminRights() {
    let roles = user && user.data && user.data.roles;
    let find = roles.includes("SuperAdmin");
    if (find) return true;
    else return false;
  }
  function adminsRights() {
    let roles = user && user.data && user.data.roles;
    const conditions = ["SuperAdmin", "Admin"];
    let find = conditions.some((role) => roles.includes(role));
    if (find) return true;
    else return false;
  }

  function onlyAdminsRights() {
    let roles = user && user.data && user.data.roles;
    const conditions = ["Admin"];
    let find = conditions.some((role) => roles.includes(role));
    if (find) return true;
    else return false;
  }

  function doctorRights() {
    let roles = user && user.data && user.data.roles;
    const conditions = ["Doctor"];
    let find = conditions.some((role) => roles.includes(role));
    if (find) return true;
    else return false;
  }

  const handleDashboard = (current) => {
    let option = [...dashboardOption];

    if (current === PATH.IMMUNIZATION_DASHBOARD) {
      return setDashboard(option[1]);
    }
    if (current === PATH.ANC_DASHBOARD) {
      return setDashboard(option[2]);
    }

    return setDashboard(option[0]);
  };

  useEffect(() => {
    handleDashboard(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/* {layoutProps.viewUserDisplay && ( */}
        {adminsRights() && (
          <>
            <li
              className={`menu-item d-md-block d-lg-none ${getMenuItemActive(
                PATH.MAIN_DASHBOARD,
                false
              )} ${getMenuItemActive(
                PATH.ANC_DASHBOARD,
                false
              )} ${getMenuItemActive(PATH.IMMUNIZATION_DASHBOARD, false)}`}
              aria-haspopup="true"
            >
              {superAdminRights() && (
                <Select
                  options={dashboardOption}
                  className="w230 mx-auto mb-5"
                  onChange={(value) => handleOnChange(value)}
                  value={dashboard}
                />
              )}

              {onlyAdminsRights() && (
                <Select
                  options={[
                    { value: 1, label: "Global Dashboard" },
                    { value: 2, label: "Immunization" },
                  ]}
                  className="w230 mx-auto mb-5"
                  onChange={(value) => handleOnChange(value)}
                  value={dashboard}
                />
              )}
            </li>
            {/* )} */}
            {/*begin::1 Level*/}
            <li
              className={`menu-item ${getMenuItemActive(
                PATH.MAIN_DASHBOARD,
                false
              )} ${getMenuItemActive(
                PATH.ANC_DASHBOARD,
                false
              )} ${getMenuItemActive(PATH.IMMUNIZATION_DASHBOARD, false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to={PATH.MAIN_DASHBOARD}>
                <span className="svg-icon menu-icon">
                  <FaTachometerAlt size="18" className="h-auto w-auto" />
                </span>
                <span className="menu-text">Dashboard</span>
              </NavLink>
            </li>
          </>
        )}

        {superAdminRights() && (
          <>
            <li
              className={`menu-item ${getMenuItemActive(
                PATH.CLINIC_LIST,
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to={PATH.CLINIC_LIST}>
                <span className="svg-icon menu-icon">
                  <FaClinicMedical size="18" className="w-auto h-auto" />
                </span>
                <span className="menu-text">Clinic List</span>
                <span className="menu-arrow">
                  {counts.allCountRecords.totalClinics || 0}
                </span>
              </NavLink>
            </li>
            {/*begin::1 Level*/}
            <li
              className={`menu-item ${getMenuItemActive(
                PATH.ADMIN_LIST,
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to={PATH.ADMIN_LIST}>
                <span className="svg-icon menu-icon">
                  <FaUserShield size="18" className="w-auto h-auto" />
                </span>
                <span className="menu-text">Admin List</span>
                <span className="menu-arrow">
                  {counts.allCountRecords.totalAdmins || 0}
                </span>
              </NavLink>
            </li>
            {/*end::1 Level*/}
          </>
        )}
        {/*begin::1 Level*/}
        {adminsRights() && (
          <>
            <li
              className={`menu-item ${getMenuItemActive(
                PATH.DOCTOR_LIST,
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to={PATH.DOCTOR_LIST}>
                <span className="svg-icon menu-icon">
                  <FaUserMd size="18" className="h-auto w-auto" />
                </span>
                <span className="menu-text">Doctor List</span>
                <span className="menu-arrow">
                  {counts.allCountRecords.totalDoctors || 0}
                </span>
              </NavLink>
            </li>
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            <li
              className={`menu-item ${getMenuItemActive(
                PATH.CARE_GIVER_LIST,
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to={PATH.CARE_GIVER_LIST}>
                <span className="svg-icon menu-icon">
                  <FaHandHoldingHeart size="18" className="h-auto w-auto" />
                </span>
                <span className="menu-text">Caregiver List</span>
                <span className="menu-arrow">
                  {counts.allCountRecords.totalCaregivers || 0}
                </span>
              </NavLink>
            </li>
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            <li
              className={`menu-item ${getMenuItemActive(
                PATH.CHILD_LIST,
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to={PATH.CHILD_LIST}>
                <span className="svg-icon menu-icon">
                  <FaBaby size="18" className="h-auto w-auto" />
                </span>
                <span className="menu-text">Child List</span>
                <span className="menu-arrow">
                  {counts.allCountRecords.totalChilds || 0}
                </span>
              </NavLink>
            </li>
            {/*end::1 Level*/}
            {/*begin::1 Level*/}
          </>
        )}
        <li
          className={`menu-item ${getMenuItemActive(PATH.PATIENT_LIST, false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to={PATH.PATIENT_LIST}>
            <span className="svg-icon menu-icon">
              <FaHospitalUser size="18" className="h-auto w-auto" />
            </span>
            <span className="menu-text">Patients List</span>
            <span className="menu-arrow">
              {counts.allCountRecords.totalPatients || 0}
            </span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {superAdminRights() && (
          <>
            {/*begin::1 Level*/}
            <li
              className={`menu-item ${getMenuItemActive(
                PATH.LEARNING_CENTRE,
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to={PATH.LEARNING_CENTRE}>
                <span className="svg-icon menu-icon">
                  <FaVideo size="18" className="w-auto h-auto" />
                </span>
                <span className="menu-text">Video Learning Centre</span>
              </NavLink>
            </li>
            {/*end::1 Level*/}
          </>
        )}
        {doctorRights() && (
          <li
            className={`menu-item ${getMenuItemActive(PATH.SCHEDULE, false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={PATH.SCHEDULE}>
              <span className="svg-icon menu-icon">
                <FaVideo size="18" className="w-auto h-auto" />
              </span>
              <span className="menu-text">Schedule</span>
            </NavLink>
          </li>
        )}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
