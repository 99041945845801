import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {
  mainDashboardReducer,
  immunizationDashboardReducer,
  clinicsReducer,
  adminReducer,
  patientReducer,
  learningCentreReducer,
  careGiverReducer,
  childReducer,
  doctorReducer,
  genericReducer,
  scheduleReducer,
} from "./reducers";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  mainDashboard: mainDashboardReducer,
  immunizationDashboard: immunizationDashboardReducer,
  clinicsReducer: clinicsReducer,
  adminReducer: adminReducer,
  patientReducer: patientReducer,
  learningCentre: learningCentreReducer,
  careGiverReducer: careGiverReducer,
  childReducer: childReducer,
  doctorReducer: doctorReducer,
  genericReducer: genericReducer,
  scheduleReducer: scheduleReducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
