import { MAIN_DASHBOARD } from "../actions/utilities";

const INITIAL_STATE = {
  getStatsLoading: false,
  getStatsSuccess: false,
  getStatsFailure: false,
  getStatsError: null,
  mainDashboardStats: [],

  getHistoryGraphLoading: false,
  getHistoryGraphSuccess: false,
  getHistoryGraphFailure: false,
  getHistoryGraphError: null,
  historyGraphData: [],

  getCaseProfileLoading: false,
  getCaseProfileSuccess: false,
  getCaseProfileFailure: false,
  getCaseProfileError: null,
  caseProfileData: [],
  totalcaseProfileCount: 0,

  getSmsNudgesListLoading: false,
  getSmsNudgesListSuccess: false,
  getSmsNudgesListFailure: false,
  getSmsNudgesListError: null,
  SmsNudgesListData: [],

  getAllCountLoading: false,
  getAllCountSuccess: false,
  getAllCountFailure: false,
  getAllCountError: null,
  allCountRecords: [],

  exportHistoryGraphDataLoading: false,
  exportHistoryGraphDataSuccess: false,
  exportHistoryGraphDataFailure: false,
  exportHistoryGraphDataError: null,
  exportHistoryGraphData: null,

  exportStatsDataLoading: false,
  exportStatsDataSuccess: false,
  exportStatsDataFailure: false,
  exportStatsDataError: null,
  exportStatsData: null,
};

export const mainDashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MAIN_DASHBOARD.GET_STATS_REQUEST:
      return {
        ...state,
        getStatsLoading: true,
        getStatsSuccess: false,
        getStatsFailure: false,
        getStatsError: null,
      };
    case MAIN_DASHBOARD.GET_STATS_FAILURE:
      return {
        ...state,
        getStatsLoading: false,
        getStatsSuccess: false,
        getStatsFailure: true,
        getStatsError: action.payload,
      };
    case MAIN_DASHBOARD.GET_STATS_SUCCESS:
      return {
        ...state,
        getStatsLoading: false,
        getStatsSuccess: true,
        getStatsFailure: false,
        getStatsError: null,
        mainDashboardStats: action.payload,
      };
    case MAIN_DASHBOARD.GET_HISTORY_GRAPH_REQUEST:
      return {
        ...state,
        getHistoryGraphLoading: true,
        getHistoryGraphSuccess: false,
        getHistoryGraphFailure: false,
        getHistoryGraphError: null,
        historyGraphData: [],
      };
    case MAIN_DASHBOARD.GET_HISTORY_GRAPH_FAILURE:
      return {
        ...state,
        getHistoryGraphLoading: false,
        getHistoryGraphSuccess: false,
        getHistoryGraphFailure: true,
        getHistoryGraphError: action.payload,
      };
    case MAIN_DASHBOARD.GET_HISTORY_GRAPH_SUCCESS:
      return {
        ...state,
        getHistoryGraphLoading: false,
        getHistoryGraphSuccess: true,
        getHistoryGraphFailure: false,
        getHistoryGraphError: null,
        historyGraphData: action.payload,
      };
    case MAIN_DASHBOARD.GET_CASE_PROFILE_TABLE_REQUEST:
      return {
        ...state,
        getCaseProfileLoading: true,
        getCaseProfileSuccess: false,
        getCaseProfileFailure: false,
        getCaseProfileError: null,
      };
    case MAIN_DASHBOARD.GET_CASE_PROFILE_TABLE_FAILURE:
      return {
        ...state,
        getCaseProfileLoading: false,
        getCaseProfileSuccess: false,
        getCaseProfileFailure: true,
        getCaseProfileError: action.payload,
      };
    case MAIN_DASHBOARD.GET_CASE_PROFILE_TABLE_SUCCESS:
      return {
        ...state,
        getCaseProfileLoading: false,
        getCaseProfileSuccess: true,
        getCaseProfileFailure: false,
        getCaseProfileError: null,
        caseProfileData: action.payload,
        totalcaseProfileCount: action.info.count,
      };
    case MAIN_DASHBOARD.GET_SMS_NUDGES_LIST_REQUEST:
      return {
        ...state,
        getSmsNudgesListLoading: true,
        getSmsNudgesListSuccess: false,
        getSmsNudgesListFailure: false,
        getSmsNudgesListError: null,
        SmsNudgesListData: [],
      };
    case MAIN_DASHBOARD.GET_SMS_NUDGES_LIST_FAILURE:
      return {
        ...state,
        getSmsNudgesListLoading: false,
        getSmsNudgesListSuccess: false,
        getSmsNudgesListFailure: true,
        getSmsNudgesListError: action.payload,
      };
    case MAIN_DASHBOARD.GET_SMS_NUDGES_LIST_SUCCESS:
      return {
        ...state,
        getSmsNudgesListLoading: false,
        getSmsNudgesListSuccess: true,
        getSmsNudgesListFailure: false,
        getSmsNudgesListError: null,
        SmsNudgesListData: action.payload,
        totalSmsNudgesCount: action.info.count,
      };
    case MAIN_DASHBOARD.GET_ALL_COUNT_REQUEST:
      return {
        ...state,
        getAllCountLoading: true,
        getAllCountSuccess: false,
        getAllCountFailure: false,
        getAllCountError: null,
        allCountRecords: [],
      };
    case MAIN_DASHBOARD.GET_ALL_COUNT_FAILURE:
      return {
        ...state,
        getAllCountLoading: false,
        getAllCountSuccess: false,
        getAllCountFailure: true,
        getAllCountError: action.payload,
      };
    case MAIN_DASHBOARD.GET_ALL_COUNT_SUCCESS:
      return {
        ...state,
        getAllCountLoading: false,
        getAllCountSuccess: true,
        getAllCountFailure: false,
        getAllCountError: null,
        allCountRecords: action.payload,
      };
    case MAIN_DASHBOARD.EXPORT_HISTORY_GRAPH_DATA_REQUEST:
      return {
        ...state,
        exportHistoryGraphDataLoading: true,
        exportHistoryGraphDataSuccess: false,
        exportHistoryGraphDataFailure: false,
        exportHistoryGraphDataError: null,
        exportHistoryGraphData: null,
      };
    case MAIN_DASHBOARD.EXPORT_HISTORY_GRAPH_DATA_FAILURE:
      return {
        ...state,
        exportHistoryGraphDataLoading: false,
        exportHistoryGraphDataSuccess: false,
        exportHistoryGraphDataFailure: true,
        exportHistoryGraphDataError: action.payload,
      };
    case MAIN_DASHBOARD.EXPORT_HISTORY_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        exportHistoryGraphDataLoading: false,
        exportHistoryGraphDataSuccess: true,
        exportHistoryGraphDataFailure: false,
        exportHistoryGraphDataError: null,
        exportHistoryGraphData: action.payload,
      };
    case MAIN_DASHBOARD.EXPORT_STATS_DATA_REQUEST:
      return {
        ...state,
        exportStatsDataLoading: true,
        exportStatsDataSuccess: false,
        exportStatsDataFailure: false,
        exportStatsDataError: null,
        exportStatsData: null,
      };
    case MAIN_DASHBOARD.EXPORT_STATS_DATA_FAILURE:
      return {
        ...state,
        exportStatsDataLoading: false,
        exportStatsDataSuccess: false,
        exportStatsDataFailure: true,
        exportStatsDataError: action.payload,
      };
    case MAIN_DASHBOARD.EXPORT_STATS_DATA_SUCCESS:
      return {
        ...state,
        exportStatsDataLoading: false,
        exportStatsDataSuccess: true,
        exportStatsDataFailure: false,
        exportStatsDataError: null,
        exportStatsData: action.payload,
      };

    default:
      return state;
  }
};
