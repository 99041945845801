import { request, success, failure, CHILD_LIST } from "./utilities";
import {
  getAllChildsApi,
  getChildApi,
  getChildEditApi,
  updateChildApi,
} from "../api";

export function getAllChildsAction(data) {
  return (dispatch) => {
    dispatch(request(CHILD_LIST.GET_CHILDS_LIST_REQUEST));
    getAllChildsApi(data).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(CHILD_LIST.GET_CHILDS_LIST_SUCCESS, response.data.data, {
              count: response.data.totalRecords,
            })
          );
        } else {
          dispatch(
            failure(CHILD_LIST.GET_CHILDS_LIST_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(CHILD_LIST.GET_CHILDS_LIST_FAILURE, error.message));
      }
    );
  };
}

export function getChildAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(CHILD_LIST.GET_SINGLE_CHILD_REQUEST));
    getChildApi(data)
      .then(
        (response) => {
          if (response.data.success === true) {
            onSuccess = true;

            dispatch(
              success(CHILD_LIST.GET_SINGLE_CHILD_SUCCESS, response.data.data)
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                CHILD_LIST.GET_SINGLE_CHILD_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(failure(CHILD_LIST.GET_SINGLE_CHILD_FAILURE, error.message));
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function getChildEditAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(CHILD_LIST.GET_SINGLE_CHILD_EDIT_REQUEST));
    getChildEditApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;

            dispatch(
              success(
                CHILD_LIST.GET_SINGLE_CHILD_EDIT_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                CHILD_LIST.GET_SINGLE_CHILD_EDIT_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(CHILD_LIST.GET_SINGLE_CHILD_EDIT_FAILURE, error.message)
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function updateChildAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(CHILD_LIST.UPDATE_SINGLE_CHILD_REQUEST));
    updateChildApi(data)
      .then(
        (response) => {
          if (response.data.success === true) {
            onSuccess = true;

            dispatch(
              success(
                CHILD_LIST.UPDATE_SINGLE_CHILD_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                CHILD_LIST.UPDATE_SINGLE_CHILD_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(CHILD_LIST.UPDATE_SINGLE_CHILD_FAILURE, error.message)
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          console.log(!!onSuccessCallback, "SUCCESS");
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}
