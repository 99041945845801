import axios from "axios";
import { API_PATH } from "../config";

/**
 * Get All Clinics List
 *
 * @param {Object} filters
 * @return {*} Promise
 */
export function getAllClinicsListApi(filters) {
  return axios.get(API_PATH.CLINICS.getAllCLinicsList, {
    params: filters,
  });
}

/**
 * Add New Clinic
 *
 * @param {Object} filters
 * @return {*} Promise
 */
export function addNewClinicApi(data) {
  return axios.post(API_PATH.CLINICS.addNewCLinic, data, {
    // params: data,
  });
}

/**
 * Get Single Clinics Detail
 *
 * @param {Object} filters
 * @return {*} Promise
 */
export function getSingleClinicDetailsApi(clinicId) {
  return axios.get(`${API_PATH.CLINICS.getSingleCLinic}?ClinicId=${clinicId}`);
}

/**
 * Get All Clinics List for Select Filter
 *
 * @return {*} Promise
 */
export function getClinicsForSelectApi() {
  return axios.get(
    `${API_PATH.CLINICS.getAllCLinicsList}?PageNumber=1&PageSize=1000`
  );
}
