const baseURL = process.env.REACT_APP_API_URL;

export const API_PATH = {
  AUTH_API: {
    LOGIN_URL: `${baseURL}/account/getToken`,
    ME_URL: `${baseURL}/user/get`,
    REQUEST_PASSWORD_URL: `${baseURL}/Account/ForgotPassword`,
    CHANGE_PASSWORD_URL: `${baseURL}/Account/ResetPassword`,
    REGISTER_URL: `${baseURL}/api/auth/register`,
  },
  GENERAL_DASHBOARD: {
    getStats: `${baseURL}/GeneralDashboard/GetStats`,
    getHistoryChart: `${baseURL}/GeneralDashboard/GetHistoryGraph`,
    getCaseProfiles: `${baseURL}/GeneralDashboard/GetCaseProfile`,
    getSmsNudges: `${baseURL}/GeneralDashboard/GetSMSNudges`,
    getAllCount: `${baseURL}/GeneralDashboard/GetAllCount`,
    getHistoryExcel: `${baseURL}/GeneralDashboard/GetHistoryExcel`,
    getStatsExcel: `${baseURL}/GeneralDashboard/GetStatsExcel`,
  },
  IMMUNIZATION_DASHBOARD: {
    getStats: `${baseURL}/ImmunizationDashboard/GetStats`,
    getImmunizationChart: `${baseURL}/ImmunizationDashboard/GetImmunizationsChart`,
    getCompletedImmunization: `${baseURL}/ImmunizationDashboard/GetCompletedImmunizations`,
    getIncompletedImmunization: `${baseURL}/ImmunizationDashboard/GetIncompleteImmunizations`,
    getMissedImmunization: `${baseURL}/ImmunizationDashboard/GetMissedImmunizations`,
    getChildImmunizationDetails: `${baseURL}/ImmunizationDashboard/GetChildImmunizationsDetails`,
    getPatientVaccinations: `${baseURL}/ImmunizationDashboard/GetMotherVaccinations`,
    exportStatsExcel: `${baseURL}/ImmunizationDashboard/GetStatsExcel`,
    exportMissedImmunizationExcel: `${baseURL}/ImmunizationDashboard/GetMissedImmunizationsExcel`,
    exportChildImmunizationsExcel: `${baseURL}/ImmunizationDashboard/GetChildImmunizationsDetailsExcel`,
  },
  CLINICS: {
    getAllCLinicsList: `${baseURL}/Clinic/GetAll`,
    addNewCLinic: `${baseURL}/Clinic/Post`,
    getSingleCLinic: `${baseURL}/Clinic/GetClinicById`,
  },
  ADMIN: {
    getAllAdminList: `${baseURL}/Admin/GetAll`,
    getSingleAdmin: `${baseURL}/Admin/GetAdminById`,
    setAdminActive: `${baseURL}/Admin/SetActiveAdmin`,
    addEditAdmin: `${baseURL}/Admin/Save`,
    getAdminClinicList: `${baseURL}/Admin/GetClinicsByAdmin`,
    assignClinics: `${baseURL}/Admin/AssignClinics`,
  },
  PATIENT: {
    getPatientList: `${baseURL}/Patient/GetAll`,
    getSinglePatient: `${baseURL}/Patient/Get`,
  },
  LEARNING_CENTRE: {
    addVideo: `${baseURL}/VideoLearning/AddVideo`,
    getVideos: `${baseURL}/VideoLearning/GetVideos`,
    deleteVideo: `${baseURL}/VideoLearning/DeleteVideo`,
  },
  CARE_GIVER: {
    getCareGiverList: `${baseURL}/Caregiver/GetAll`,
    getSingleCareGiver: `${baseURL}/Caregiver/GetCaregiverById`,
    addCaregiver: `${baseURL}/Patient/Add`,
    updateCaregiver: `${baseURL}/Patient/Update`,
    getDoctorByClinic: `${baseURL}/Doctor/GetAll`,
  },
  CHILDS: {
    getChildsList: `${baseURL}/Child/GetAll`,
    getChild: `${baseURL}/Caregiver/GetCaregiverById`,
    getChildEdit: `${baseURL}/Child/GetEdit`,
    updateChild: `${baseURL}/Child/Update`,
  },
  DOCTOR: {
    getAllDoctorList: `${baseURL}/Doctor/GetAll`,
    getSingleDoctor: `${baseURL}/Doctor/GetDoctorById`,
    setDoctorActive: `${baseURL}/Admin/SetActiveDoctor`,
    addEditDoctor: `${baseURL}/Admin/Save`,
  },

  GENERIC: {
    activateUser: `${baseURL}/admin/User/Activate`,
    deactivateUser: `${baseURL}/admin/User/Deactivate`,
  },
  SCHEDULE: {
    getDoctorScheduleList: `${baseURL}/Schedule/GetByDoctor`,
    addSchedule: `${baseURL}/Schedule/Add`,
    editSchedule: `${baseURL}/Schedule/Update`,
    getSingleScheduleDetail: `${baseURL}/Schedule/Get`,
  },
};
