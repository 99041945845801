export function request(actionType) {
  return {
    type: actionType,
  };
}

export function success(actionType, response, info) {
  return {
    type: actionType,
    payload: response,
    info: info,
  };
}

export function failure(actionType, error) {
  return {
    type: actionType,
    payload: error,
  };
}

export const MAIN_DASHBOARD = {
  GET_STATS_REQUEST: "GET_STATS_REQUEST",
  GET_STATS_SUCCESS: "GET_STATS_SUCCESS",
  GET_STATS_FAILURE: "GET_STATS_FAILURE",

  GET_HISTORY_GRAPH_REQUEST: "GET_HISTORY_GRAPH_REQUEST",
  GET_HISTORY_GRAPH_SUCCESS: "GET_HISTORY_GRAPH_SUCCESS",
  GET_HISTORY_GRAPH_FAILURE: "GET_HISTORY_GRAPH_FAILURE",

  GET_CASE_PROFILE_TABLE_REQUEST: "GET_CASE_PROFILE_TABLE_REQUEST",
  GET_CASE_PROFILE_TABLE_SUCCESS: "GET_CASE_PROFILE_TABLE_SUCCESS",
  GET_CASE_PROFILE_TABLE_FAILURE: "GET_CASE_PROFILE_TABLE_FAILURE",

  GET_SMS_NUDGES_LIST_REQUEST: "GET_SMS_NUDGES_LIST_REQUEST",
  GET_SMS_NUDGES_LIST_SUCCESS: "GET_SMS_NUDGES_LIST_SUCCESS",
  GET_SMS_NUDGES_LIST_FAILURE: "GET_SMS_NUDGES_LIST_FAILURE",

  GET_ALL_COUNT_REQUEST: "GET_ALL_COUNT_REQUEST",
  GET_ALL_COUNT_SUCCESS: "GET_ALL_COUNT_SUCCESS",
  GET_ALL_COUNT_FAILURE: "GET_ALL_COUNT_FAILURE",

  EXPORT_HISTORY_GRAPH_DATA_REQUEST: "EXPORT_HISTORY_GRAPH_DATA_REQUEST",
  EXPORT_HISTORY_GRAPH_DATA_SUCCESS: "EXPORT_HISTORY_GRAPH_DATA_SUCCESS",
  EXPORT_HISTORY_GRAPH_DATA_FAILURE: "EXPORT_HISTORY_GRAPH_DATA_FAILURE",

  EXPORT_STATS_DATA_REQUEST: "EXPORT_STATS_DATA_REQUEST",
  EXPORT_STATS_DATA_SUCCESS: "EXPORT_STATS_DATA_SUCCESS",
  EXPORT_STATS_DATA_FAILURE: "EXPORT_STATS_DATA_FAILURE",
};

export const IMMUNIZATION_DASHBOARD = {
  GET_IMZ_STATS_REQUEST: "GET_IMZ_STATS_REQUEST",
  GET_IMZ_STATS_SUCCESS: "GET_IMZ_STATS_SUCCESS",
  GET_IMZ_STATS_FAILURE: "GET_IMZ_STATS_FAILURE",

  GET_IMMUNIZATION_CHART_REQUEST: "GET_IMMUNIZATION_CHART_REQUEST",
  GET_IMMUNIZATION_CHART_SUCCESS: "GET_IMMUNIZATION_CHART_SUCCESS",
  GET_IMMUNIZATION_CHART_FAILURE: "GET_IMMUNIZATION_CHART_FAILURE",

  GET_COMPLETED_IMMUNIZATION_REQUEST: "GET_COMPLETED_IMMUNIZATION_REQUEST",
  GET_COMPLETED_IMMUNIZATION_SUCCESS: "GET_COMPLETED_IMMUNIZATION_SUCCESS",
  GET_COMPLETED_IMMUNIZATION_FAILURE: "GET_COMPLETED_IMMUNIZATION_FAILURE",

  GET_INCOMPLETED_IMMUNIZATION_REQUEST: "GET_INCOMPLETED_IMMUNIZATION_REQUEST",
  GET_INCOMPLETED_IMMUNIZATION_SUCCESS: "GET_INCOMPLETED_IMMUNIZATION_SUCCESS",
  GET_INCOMPLETED_IMMUNIZATION_FAILURE: "GET_INCOMPLETED_IMMUNIZATION_FAILURE",

  GET_MISSED_IMMUNIZATION_REQUEST: "GET_MISSED_IMMUNIZATION_REQUEST",
  GET_MISSED_IMMUNIZATION_SUCCESS: "GET_MISSED_IMMUNIZATION_SUCCESS",
  GET_MISSED_IMMUNIZATION_FAILURE: "GET_MISSED_IMMUNIZATION_FAILURE",

  GET_CHILD_IMMUNIZATION_REQUEST: "GET_CHILD_IMMUNIZATION_REQUEST",
  GET_CHILD_IMMUNIZATION_SUCCESS: "GET_CHILD_IMMUNIZATION_SUCCESS",
  GET_CHILD_IMMUNIZATION_FAILURE: "GET_CHILD_IMMUNIZATION_FAILURE",

  GET_PATIENT_VACCINATION_REQUEST: "GET_PATIENT_VACCINATION_REQUEST",
  GET_PATIENT_VACCINATION_SUCCESS: "GET_PATIENT_VACCINATION_SUCCESS",
  GET_PATIENT_VACCINATION_FAILURE: "GET_PATIENT_VACCINATION_FAILURE",

  EXPORT_STATS_EXCEL_REQUEST: "EXPORT_STATS_EXCEL_REQUEST",
  EXPORT_STATS_EXCEL_SUCCESS: "EXPORT_STATS_EXCEL_SUCCESS",
  EXPORT_STATS_EXCEL_FAILURE: "EXPORT_STATS_EXCEL_FAILURE",

  EXPORT_MISSED_IMMUNIZATION_REQUEST: "EXPORT_MISSED_IMMUNIZATION_REQUEST",
  EXPORT_MISSED_IMMUNIZATION_SUCCESS: "EXPORT_MISSED_IMMUNIZATION_SUCCESS",
  EXPORT_MISSED_IMMUNIZATION_FAILURE: "EXPORT_MISSED_IMMUNIZATION_FAILURE",

  EXPORT_CHILD_IMMUNIZATION_REQUEST: "EXPORT_CHILD_IMMUNIZATION_REQUEST",
  EXPORT_CHILD_IMMUNIZATION_SUCCESS: "EXPORT_CHILD_IMMUNIZATION_SUCCESS",
  EXPORT_CHILD_IMMUNIZATION_FAILURE: "EXPORT_CHILD_IMMUNIZATION_FAILURE",
};

export const CLINICS_REQUEST = {
  GET_CLINICS_LIST_REQUEST: "GET_CLINICS_LIST_REQUEST",
  GET_CLINICS_LIST_SUCCESS: "GET_CLINICS_LIST_SUCCESS",
  GET_CLINICS_LIST_FAILURE: "GET_CLINICS_LIST_FAILURE",

  ADD_NEW_CLINIC_REQUEST: "ADD_NEW_CLINIC_REQUEST",
  ADD_NEW_CLINIC_SUCCESS: "ADD_NEW_CLINIC_SUCCESS",
  ADD_NEW_CLINIC_FAILURE: "ADD_NEW_CLINIC_FAILURE",

  GET_SINGLE_CLINIC_DETAILS_REQUEST: "GET_SINGLE_CLINIC_DETAILS_REQUEST",
  GET_SINGLE_CLINIC_DETAILS_SUCCESS: "GET_SINGLE_CLINIC_DETAILS_SUCCESS",
  GET_SINGLE_CLINIC_DETAILS_FAILURE: "GET_SINGLE_CLINIC_DETAILS_FAILURE",

  GET_CLINICS_LIST_SELECT_REQUEST: "GET_CLINICS_LIST_SELECT_REQUEST",
  GET_CLINICS_LIST_SELECT_SUCCESS: "GET_CLINICS_LIST_SELECT_SUCCESS",
  GET_CLINICS_LIST_SELECT_FAILURE: "GET_CLINICS_LIST_SELECT_FAILURE",
};

export const ADMIN_REQUEST = {
  GET_ADMIN_LIST_REQUEST: "GET_ADMIN_LIST_REQUEST",
  GET_ADMIN_LIST_SUCCESS: "GET_ADMIN_LIST_SUCCESS",
  GET_ADMIN_LIST_FAILURE: "GET_ADMIN_LIST_FAILURE",

  GET_SINGLE_ADMIN_DETAILS_REQUEST: "GET_SINGLE_ADMIN_DETAILS_REQUEST",
  GET_SINGLE_ADMIN_DETAILS_SUCCESS: "GET_SINGLE_ADMIN_DETAILS_SUCCESS",
  GET_SINGLE_ADMIN_DETAILS_FAILURE: "GET_SINGLE_ADMIN_DETAILS_FAILURE",

  SET_ADMIN_ACTIVE_REQUEST: "GET_ADMIN_ACTIVE_REQUEST",
  SET_ADMIN_ACTIVE_SUCCESS: "GET_ADMIN_ACTIVE_SUCCESS",
  SET_ADMIN_ACTIVE_FAILURE: "GET_ADMIN_ACTIVE_FAILURE",

  ADD_EDIT_ADMIN_REQUEST: "ADD_EDIT_ADMIN_REQUEST",
  ADD_EDIT_ADMIN_SUCCESS: "ADD_EDIT_ADMIN_SUCCESS",
  ADD_EDIT_ADMIN_FAILURE: "ADD_EDIT_ADMIN_FAILURE",

  GET_ADMIN_CLINIC_LIST_REQUEST: "GET_ADMIN_CLINIC_LIST_REQUEST",
  GET_ADMIN_CLINIC_LIST_SUCCESS: "GET_ADMIN_CLINIC_LIST_SUCCESS",
  GET_ADMIN_CLINIC_LIST_FAILURE: "GET_ADMIN_CLINIC_LIST_FAILURE",

  ASSIGN_ADMIN_CLINIC_REQUEST: "ASSIGN_ADMIN_CLINIC_REQUEST",
  ASSIGN_ADMIN_CLINIC_SUCCESS: "ASSIGN_ADMIN_CLINIC_SUCCESS",
  ASSIGN_ADMIN_CLINIC_FAILURE: "ASSIGN_ADMIN_CLINIC_FAILURE",
};

export const PATIENT_REQUEST = {
  GET_PATIENT_LIST_REQUEST: "GET_PATIENT_LIST_REQUEST",
  GET_PATIENT_LIST_SUCCESS: "GET_PATIENT_LIST_SUCCESS",
  GET_PATIENT_LIST_FAILURE: "GET_PATIENT_LIST_FAILURE",

  GET_SINGLE_PATIENT_DETAIL_REQUEST: "GET_SINGLE_PATIENT_DETAIL_REQUEST",
  GET_SINGLE_PATIENT_DETAIL_SUCCESS: "GET_SINGLE_PATIENT_DETAIL_SUCCESS",
  GET_SINGLE_PATIENT_DETAIL_FAILURE: "GET_SINGLE_PATIENT_DETAIL_FAILURE",

  GET_PATIENT_SELECT_LIST_REQUEST: "GET_PATIENT_SELECT_LIST_REQUEST",
  GET_PATIENT_SELECT_LIST_SUCCESS: "GET_PATIENT_SELECT_LIST_SUCCESS",
  GET_PATIENT_SELECT_LIST_FAILURE: "GET_PATIENT_SELECT_LIST_FAILURE",

  GET_PATIENT_CHILDS_LIST_REQUEST: "GET_PATIENT_CHILDS_LIST_REQUEST",
  GET_PATIENT_CHILDS_LIST_SUCCESS: "GET_PATIENT_CHILDS_LIST_SUCCESS",
  GET_PATIENT_CHILDS_LIST_FAILURE: "GET_PATIENT_CHILDS_LIST_FAILURE",
};

export const LEARNING_CENTRE = {
  GET_VIDEOS_LEARNING_CENTRE_REQUEST: "GET_VIDEOS_LEARNING_CENTRE_REQUEST",
  GET_VIDEOS_LEARNING_CENTRE_SUCCESS: "GET_VIDEOS_LEARNING_CENTRE_SUCCESS",
  GET_VIDEOS_LEARNING_CENTRE_FAILURE: "GET_VIDEOS_LEARNING_CENTRE_FAILURE",

  ADD_VIDEO_LEARNING_CENTRE_REQUEST: "ADD_VIDEO_LEARNING_CENTRE_REQUEST",
  ADD_VIDEO_LEARNING_CENTRE_SUCCESS: "ADD_VIDEO_LEARNING_CENTRE_SUCCESS",
  ADD_VIDEO_LEARNING_CENTRE_FAILURE: "ADD_VIDEO_LEARNING_CENTRE_FAILURE",

  DELETE_VIDEO_LEARNING_CENTRE_REQUEST: "DELETE_VIDEO_LEARNING_CENTRE_REQUEST",
  DELETE_VIDEO_LEARNING_CENTRE_SUCCESS: "DELETE_VIDEO_LEARNING_CENTRE_SUCCESS",
  DELETE_VIDEO_LEARNING_CENTRE_FAILURE: "DELETE_VIDEO_LEARNING_CENTRE_FAILURE",
};

export const CARE_GIVER_REQUEST = {
  GET_CARE_GIVER_LIST_REQUEST: "GET_CARE_GIVER_LIST_REQUEST",
  GET_CARE_GIVER_LIST_SUCCESS: "GET_CARE_GIVER_LIST_SUCCESS",
  GET_CARE_GIVER_LIST_FAILURE: "GET_CARE_GIVER_LIST_FAILURE",

  GET_SINGLE_CARE_GIVER_DETAIL_REQUEST: "GET_SINGLE_CARE_GIVER_DETAIL_REQUEST",
  GET_SINGLE_CARE_GIVER_DETAIL_SUCCESS: "GET_SINGLE_CARE_GIVER_DETAIL_SUCCESS",
  GET_SINGLE_CARE_GIVER_DETAIL_FAILURE: "GET_SINGLE_CARE_GIVER_DETAIL_FAILURE",

  ADD_CARE_GIVER_REQUEST: "ADD_CARE_GIVER_REQUEST",
  ADD_CARE_GIVER_SUCCESS: "ADD_CARE_GIVER_SUCCESS",
  ADD_CARE_GIVER_FAILURE: "ADD_CARE_GIVER_FAILURE",

  UPDATE_CARE_GIVER_REQUEST: "UPDATE_CARE_GIVER_REQUEST",
  UPDATE_CARE_GIVER_SUCCESS: "UPDATE_CARE_GIVER_SUCCESS",
  UPDATE_CARE_GIVER_FAILURE: "UPDATE_CARE_GIVER_FAILURE",

  GET_DOCTOR_CARE_GIVER_REQUEST: "GET_DOCTOR_CARE_GIVER_REQUEST",
  GET_DOCTOR_CARE_GIVER_SUCCESS: "GET_DOCTOR_CARE_GIVER_SUCCESS",
  GET_DOCTOR_CARE_GIVER_FAILURE: "GET_DOCTOR_CARE_GIVER_FAILURE",
};

export const CHILD_LIST = {
  GET_CHILDS_LIST_REQUEST: "GET_CHILDS_LIST_REQUEST",
  GET_CHILDS_LIST_SUCCESS: "GET_CHILDS_LIST_SUCCESS",
  GET_CHILDS_LIST_FAILURE: "GET_CHILDS_LIST_FAILURE",

  GET_SINGLE_CHILD_REQUEST: "GET_SINGLE_CHILD_REQUEST",
  GET_SINGLE_CHILD_SUCCESS: "GET_SINGLE_CHILD_SUCCESS",
  GET_SINGLE_CHILD_FAILURE: "GET_SINGLE_CHILD_FAILURE",

  GET_SINGLE_CHILD_EDIT_REQUEST: "GET_SINGLE_CHILD_EDIT_REQUEST",
  GET_SINGLE_CHILD_EDIT_SUCCESS: "GET_SINGLE_CHILD_EDIT_SUCCESS",
  GET_SINGLE_CHILD_EDIT_FAILURE: "GET_SINGLE_CHILD_EDIT_FAILURE",

  UPDATE_SINGLE_CHILD_REQUEST: "UPDATE_SINGLE_CHILD_REQUEST",
  UPDATE_SINGLE_CHILD_SUCCESS: "UPDATE_SINGLE_CHILD_SUCCESS",
  UPDATE_SINGLE_CHILD_FAILURE: "UPDATE_SINGLE_CHILD_FAILURE",
};

export const DOCTOR_REQUEST = {
  GET_DOCTOR_LIST_REQUEST: "GET_DOCTOR_LIST_REQUEST",
  GET_DOCTOR_LIST_SUCCESS: "GET_DOCTOR_LIST_SUCCESS",
  GET_DOCTOR_LIST_FAILURE: "GET_DOCTOR_LIST_FAILURE",

  GET_SINGLE_DOCTOR_DETAILS_REQUEST: "GET_SINGLE_DOCTOR_DETAILS_REQUEST",
  GET_SINGLE_DOCTOR_DETAILS_SUCCESS: "GET_SINGLE_DOCTOR_DETAILS_SUCCESS",
  GET_SINGLE_DOCTOR_DETAILS_FAILURE: "GET_SINGLE_DOCTOR_DETAILS_FAILURE",

  ADD_EDIT_DOCTOR_REQUEST: "ADD_EDIT_DOCTOR_REQUEST",
  ADD_EDIT_DOCTOR_SUCCESS: "ADD_EDIT_DOCTOR_SUCCESS",
  ADD_EDIT_DOCTOR_FAILURE: "ADD_EDIT_DOCTOR_FAILURE",

  SET_DOCTOR_ACTIVE_REQUEST: "GET_DOCTOR_ACTIVE_REQUEST",
  SET_DOCTOR_ACTIVE_SUCCESS: "GET_DOCTOR_ACTIVE_SUCCESS",
  SET_DOCTOR_ACTIVE_FAILURE: "GET_DOCTOR_ACTIVE_FAILURE",

  GET_DOCTORS_SELECT_REQUEST: "GET_DOCTORS_SELECT_REQUEST",
  GET_DOCTORS_SELECT_SUCCESS: "GET_DOCTORS_SELECT_SUCCESS",
  GET_DOCTORS_SELECT_FAILURE: "GET_DOCTORS_SELECT_FAILURE",
};

export const GENERIC = {
  ACTIVATE_USER_REQUEST: "ACTIVATE_USER_REQUEST",
  ACTIVATE_USER_SUCCESS: "ACTIVATE_USER_SUCCESS",
  ACTIVATE_USER_FAILURE: "ACTIVATE_USER_FAILURE",

  DEACTIVATE_USER_REQUEST: "DEACTIVATE_USER_REQUEST",
  DEACTIVATE_USER_SUCCESS: "DEACTIVATE_USER_SUCCESS",
  DEACTIVATE_USER_FAILURE: "DEACTIVATE_USER_FAILURE",
};

export const SCHEDULE_REQUEST = {
  // GET_SCHEDULE_LIST_REQUEST: "GET_SCHEDULE_LIST_REQUEST",
  // GET_SCHEDULE_LIST_SUCCESS: "GET_SCHEDULE_LIST_SUCCESS",
  // GET_SCHEDULE_LIST_FAILURE: "GET_SCHEDULE_LIST_FAILURE",

  GET_DOCTOR_SCHEDULE_LIST_REQUEST: "GET_DOCTOR_SCHEDULE_LIST_REQUEST",
  GET_DOCTOR_SCHEDULE_LIST_SUCCESS: "GET_DOCTOR_SCHEDULE_LIST_SUCCESS",
  GET_DOCTOR_SCHEDULE_LIST_FAILURE: "GET_DOCTOR_SCHEDULE_LIST_FAILURE",

  GET_SINGLE_SCHEDULE_DETAILS_REQUEST: "GET_SINGLE_SCHEDULE_DETAILS_REQUEST",
  GET_SINGLE_SCHEDULE_DETAILS_SUCCESS: "GET_SINGLE_SCHEDULE_DETAILS_SUCCESS",
  GET_SINGLE_SCHEDULE_DETAILS_FAILURE: "GET_SINGLE_SCHEDULE_DETAILS_FAILURE",

  ADD_SCHEDULE_REQUEST: "ADD_SCHEDULE_REQUEST",
  ADD_SCHEDULE_SUCCESS: "ADD_SCHEDULE_SUCCESS",
  ADD_SCHEDULE_FAILURE: "ADD_SCHEDULE_FAILURE",

  EDIT_SCHEDULE_REQUEST: "EDIT_SCHEDULE_REQUEST",
  EDIT_SCHEDULE_SUCCESS: "EDIT_SCHEDULE_SUCCESS",
  EDIT_SCHEDULE_FAILURE: "EDIT_SCHEDULE_FAILURE",
};
