import { request, success, failure, GENERIC } from "./utilities";
import { activateUserApi, deactivateUserApi } from "../api";

export function activateUserAction(data) {
  return (dispatch) => {
    dispatch(request(GENERIC.ACTIVATE_USER_REQUEST));
    activateUserApi(data).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(success(GENERIC.ACTIVATE_USER_SUCCESS, response.data.data));
        } else {
          dispatch(
            failure(GENERIC.ACTIVATE_USER_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(GENERIC.ACTIVATE_USER_FAILURE, error.message));
      }
    );
  };
}

export function deactivateUserAction(data) {
  return (dispatch) => {
    dispatch(request(GENERIC.DEACTIVATE_USER_REQUEST));
    deactivateUserApi(data).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(GENERIC.DEACTIVATE_USER_SUCCESS, response.data.data)
          );
        } else {
          dispatch(
            failure(GENERIC.DEACTIVATE_USER_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(GENERIC.DEACTIVATE_USER_FAILURE, error.message));
      }
    );
  };
}
