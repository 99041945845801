import axios from "axios";
import { API_PATH } from "../config";

/**
 * Get All Child List
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function getAllChildsApi(data) {
  return axios.get(API_PATH.CHILDS.getChildsList, {
    params: data,
  });
}

/**
 * Get Single Child Record Api
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function getChildApi(data) {
  return axios.get(API_PATH.CHILDS.getChild, {
    params: data,
  });
}

/**
 * Get Edit View Data for Child Record Api
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function getChildEditApi(data) {
  return axios.get(API_PATH.CHILDS.getChildEdit, {
    params: data,
  });
}

/**
 * Update Single Child Record Api
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function updateChildApi(data) {
  return axios.put(API_PATH.CHILDS.updateChild, data);
}
