import axios from "axios";
import { API_PATH } from "../config";

/**
 * Get General Dashboard Stats Data
 *
 * @param {Object} filterData
 * @return {*} Promise
 */
export function getStatsApi(filterData) {
  return axios.get(API_PATH.GENERAL_DASHBOARD.getStats, {
    params: filterData,
  });
}

/**
 * Get History Graph Data Api
 *
 * @param {Object} filterData
 * @return {*}
 */
export function getHistoryGraphApi(filterData) {
  return axios.get(`${API_PATH.GENERAL_DASHBOARD.getHistoryChart}`, {
    params: filterData,
  });
}

/**
 * Get General Dashboard Case Table Data
 *
 * @param {Object} filterData
 * @return {*}
 */
export function getCaseProfileTableApi(filterData) {
  return axios.get(`${API_PATH.GENERAL_DASHBOARD.getCaseProfiles}`, {
    params: filterData,
  });
}

/**
 * Get All Records Count for Dashboard
 *
 * @param {Object} filterData
 * @return {*} Promise
 */
export function getAllCountApi() {
  return axios.get(API_PATH.GENERAL_DASHBOARD.getAllCount);
}

/**
 * Get Sms Nudges Table List Data
 *
 * @param {*} filterData
 * @return {*}
 */
export function getSmsNudgesListApi(filterData) {
  return axios.get(`${API_PATH.GENERAL_DASHBOARD.getSmsNudges}`, {
    params: filterData,
  });
}

/**
 * Api for Export Excel for History Graph
 *
 * @param {*} filterData
 * @return {*}
 */
export function exportHistoryGraphDataApi(filterData) {
  return axios.get(`${API_PATH.GENERAL_DASHBOARD.getHistoryExcel}`, {
    params: filterData,
    responseType: "blob",
  });
}
