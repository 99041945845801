import { request, success, failure, SCHEDULE_REQUEST } from "./utilities";
import {
  addScheduleApi,
  editScheduleApi,
  getDoctorScheduleListApi,
  getSingleScheduleDetailsApi,
} from "../api";

export function getDoctorScheduleList(filters) {
  return (dispatch) => {
    dispatch(request(SCHEDULE_REQUEST.GET_DOCTOR_SCHEDULE_LIST_REQUEST));
    getDoctorScheduleListApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              SCHEDULE_REQUEST.GET_DOCTOR_SCHEDULE_LIST_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              SCHEDULE_REQUEST.GET_DOCTOR_SCHEDULE_LIST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            SCHEDULE_REQUEST.GET_DOCTOR_SCHEDULE_LIST_FAILURE,
            error.message
          )
        );
      }
    );
  };
}

export function getSingleScheduleDetails(recordId) {
  return (dispatch) => {
    dispatch(request(SCHEDULE_REQUEST.GET_SINGLE_SCHEDULE_DETAILS_REQUEST));
    getSingleScheduleDetailsApi(recordId).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              SCHEDULE_REQUEST.GET_SINGLE_SCHEDULE_DETAILS_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              SCHEDULE_REQUEST.GET_SINGLE_SCHEDULE_DETAILS_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            SCHEDULE_REQUEST.GET_SINGLE_SCHEDULE_DETAILS_FAILURE,
            error.message
          )
        );
      }
    );
  };
}

export function addSchedule(rec, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(SCHEDULE_REQUEST.ADD_SCHEDULE_REQUEST));
    addScheduleApi(rec)
      .then(
        (response) => {
          if (response.data.success === true) {
            onSuccess = true;

            dispatch(success(SCHEDULE_REQUEST.ADD_SCHEDULE_SUCCESS, rec));
          } else {
            onFailure = true;

            dispatch(
              failure(
                SCHEDULE_REQUEST.ADD_SCHEDULE_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(SCHEDULE_REQUEST.ADD_SCHEDULE_FAILURE, error.message)
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function editSchedule(rec, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(SCHEDULE_REQUEST.EDIT_SCHEDULE_REQUEST));
    editScheduleApi(rec)
      .then(
        (response) => {
          if (response.data.success === true) {
            onSuccess = true;

            dispatch(success(SCHEDULE_REQUEST.EDIT_SCHEDULE_SUCCESS, rec));
          } else {
            onFailure = true;

            dispatch(
              failure(
                SCHEDULE_REQUEST.EDIT_SCHEDULE_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(SCHEDULE_REQUEST.EDIT_SCHEDULE_FAILURE, error.message)
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}
