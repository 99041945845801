import axios from "axios";
import { API_PATH } from "../config";

/**
 * Get CareGiver List
 *
 * @param {Object} filters
 * @return {*} Promise
 */
export function getCareGiverListApi(filters) {
  return axios.get(API_PATH.CARE_GIVER.getCareGiverList, {
    params: filters,
  });
}

/**
 * Get Single CareGiver Details
 *
 * @param {Object} careGiverId
 * @return {*} Promise
 */
export function getSingleCareGiverDetailApi(careGiverId) {
  return axios.get(
    `${API_PATH.CARE_GIVER.getSingleCareGiver}?PatientId=${careGiverId}`
  );
}

/**
 * Add CareGiver Api
 *
 * @param {Object} careGiverId
 * @return {*} Promise
 */
export function addCaregiverApi(data) {
  return axios.post(API_PATH.CARE_GIVER.addCaregiver, data);
}

/**
 * Update CareGiver Api
 *
 * @param {Object} careGiverId
 * @return {*} Promise
 */
export function updateCaregiverApi(data) {
  return axios.put(API_PATH.CARE_GIVER.updateCaregiver, data);
}

/**
 * Get Doctor By Clinic Id in Caregiver Api
 *
 * @param {Object} careGiverId
 * @return {*} Promise
 */
export function getDoctorCaregiverApi(data) {
  return axios.get(API_PATH.CARE_GIVER.getDoctorByClinic, {
    params: data,
  });
}
