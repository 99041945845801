import { request, success, failure, CARE_GIVER_REQUEST } from "./utilities";
import {
  addCaregiverApi,
  getCareGiverListApi,
  getDoctorCaregiverApi,
  getSingleCareGiverDetailApi,
  updateCaregiverApi,
} from "../api";

export function getCareGiverList(filters) {
  return (dispatch) => {
    dispatch(request(CARE_GIVER_REQUEST.GET_CARE_GIVER_LIST_REQUEST));
    getCareGiverListApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              CARE_GIVER_REQUEST.GET_CARE_GIVER_LIST_SUCCESS,
              response.data.data,
              {
                count: response.data.totalRecords,
              }
            )
          );
        } else {
          dispatch(
            failure(
              CARE_GIVER_REQUEST.GET_CARE_GIVER_LIST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(CARE_GIVER_REQUEST.GET_CARE_GIVER_LIST_FAILURE, error.message)
        );
      }
    );
  };
}

export function getSingleCareGiverDetail(id) {
  return (dispatch) => {
    dispatch(request(CARE_GIVER_REQUEST.GET_SINGLE_CARE_GIVER_DETAIL_REQUEST));
    getSingleCareGiverDetailApi(id).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              CARE_GIVER_REQUEST.GET_SINGLE_CARE_GIVER_DETAIL_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              CARE_GIVER_REQUEST.GET_SINGLE_CARE_GIVER_DETAIL_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            CARE_GIVER_REQUEST.GET_SINGLE_CARE_GIVER_DETAIL_FAILURE,
            error.message
          )
        );
      }
    );
  };
}

export function addCareGiverAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(CARE_GIVER_REQUEST.ADD_CARE_GIVER_REQUEST));
    addCaregiverApi(data)
      .then(
        (response) => {
          if (response.data.success === true) {
            onSuccess = true;

            dispatch(
              success(
                CARE_GIVER_REQUEST.ADD_CARE_GIVER_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                CARE_GIVER_REQUEST.ADD_CARE_GIVER_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(CARE_GIVER_REQUEST.ADD_CARE_GIVER_FAILURE, error.message)
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function updateCareGiverAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(CARE_GIVER_REQUEST.UPDATE_CARE_GIVER_REQUEST));
    updateCaregiverApi(data)
      .then(
        (response) => {
          onSuccess = true;

          if (response.data.success === true) {
            dispatch(
              success(
                CARE_GIVER_REQUEST.UPDATE_CARE_GIVER_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                CARE_GIVER_REQUEST.UPDATE_CARE_GIVER_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(CARE_GIVER_REQUEST.UPDATE_CARE_GIVER_FAILURE, error.message)
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function getDoctorCareGiverAction(data) {
  return (dispatch) => {
    dispatch(request(CARE_GIVER_REQUEST.GET_DOCTOR_CARE_GIVER_REQUEST));
    getDoctorCaregiverApi(data).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              CARE_GIVER_REQUEST.GET_DOCTOR_CARE_GIVER_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              CARE_GIVER_REQUEST.GET_DOCTOR_CARE_GIVER_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            CARE_GIVER_REQUEST.GET_DOCTOR_CARE_GIVER_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
