import * as React from "react";
const Dashboard = React.lazy(() => import("./MainDashboard"));
const ImmunizationDashboard = React.lazy(() =>
  import("./ImmunizationDashboard")
);
const AncDashboard = React.lazy(() => import("./AncDashboard"));

const ClinicList = React.lazy(() => import("./ClinicList"));
const ClinicAdd = React.lazy(() => import("./ClinicList/Pages/Add"));
const ClinicEdit = React.lazy(() => import("./ClinicList/Pages/Edit"));
const ClinicView = React.lazy(() => import("./ClinicList/Pages/View"));

const AdminList = React.lazy(() => import("./AdminList"));
const AdminAdd = React.lazy(() => import("./AdminList/Pages/Add"));
const AdminEdit = React.lazy(() => import("./AdminList/Pages/Edit"));
const AdminView = React.lazy(() => import("./AdminList/Pages/View"));

const DoctorList = React.lazy(() => import("./DoctorList"));
const DoctorAdd = React.lazy(() => import("./DoctorList/Pages/Add"));
const DoctorEdit = React.lazy(() => import("./DoctorList/Pages/Edit"));
const DoctorView = React.lazy(() => import("./DoctorList/Pages/View"));

const CareGiverList = React.lazy(() => import("./CaregiverList"));
const CaregiverAdd = React.lazy(() => import("./CaregiverList/Pages/Add"));
const CaregiverEdit = React.lazy(() => import("./CaregiverList/Pages/Edit"));
const CaregiverView = React.lazy(() => import("./CaregiverList/Pages/View"));

const ChildList = React.lazy(() => import("./ChildList"));
const ChildEdit = React.lazy(() => import("./ChildList/Pages/Edit"));
const ChildView = React.lazy(() => import("./ChildList/Pages/View"));

const PatientList = React.lazy(() => import("./PatientList"));
const PatientView = React.lazy(() => import("./PatientList/Pages/View"));

const LearningCentre = React.lazy(() => import("./LearningCentre"));
const VideoAdd = React.lazy(() => import("./LearningCentre/Pages/Add"));
const VideoView = React.lazy(() => import("./LearningCentre/Pages/View"));

const Schedule = React.lazy(() => import("./Schedule"));
const ScheduleAdd = React.lazy(() => import("./Schedule/Pages/Add"));
const ScheduleEdit = React.lazy(() => import("./Schedule/Pages/Edit"));


const WEB_PAGES = {
  //Dashboards
  MAIN_DASHBOARD: Dashboard,
  IMMUNIZATION_DASHBOARD: ImmunizationDashboard,
  ANC_DASHBOARD: AncDashboard,

  //Clinic List
  CLINIC_LIST: ClinicList,
  CLINIC_ADD: ClinicAdd,
  CLINIC_EDIT: ClinicEdit,
  CLINIC_VIEW: ClinicView,

  //Admin List
  ADMIN_LIST: AdminList,
  ADMIN_ADD: AdminAdd,
  ADMIN_EDIT: AdminEdit,
  ADMIN_VIEW: AdminView,

  //Doctor List
  DOCTOR_LIST: DoctorList,
  DOCTOR_ADD: DoctorAdd,
  DOCTOR_EDIT: DoctorEdit,
  DOCTOR_VIEW: DoctorView,

  // CareGiver
  CARE_GIVER_LIST: CareGiverList,
  CARE_GIVER_ADD: CaregiverAdd,
  CARE_GIVER_EDIT: CaregiverEdit,
  CARE_GIVER_VIEW: CaregiverView,

  //Child List
  CHILD_LIST: ChildList,
  CHILD_EDIT: ChildEdit,
  CHILD_VIEW: ChildView,

  //Patient List
  PATIENT_LIST: PatientList,
  PATIENT_VIEW: PatientView,

  // Others
  LEARNING_CENTRE: LearningCentre,
  LEARNING_CENTRE_VIDEO_ADD: VideoAdd,
  LEARNING_CENTRE_VIDEO_VIEW: VideoView,

  SCHEDULE: Schedule,
  SCHEDULE_ADD: ScheduleAdd,
  SCHEDULE_EDIT: ScheduleEdit,
};

export { WEB_PAGES };
