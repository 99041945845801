import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Redirect, Switch, Route } from "react-router-dom";
import {
  LayoutSplashScreen,
  // ContentRoute
} from "../_metronic/layout";
import { PATH } from "./config";
import { WEB_PAGES } from "./pages";

export default function BasePage() {
  const { user } = useSelector((state) => state.auth);
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* <Redirect exact from="/" to="/dashboard" /> */}
        {user.data.roles[0] === "SuperAdmin" ||
        user.data.roles[0] === "Admin" ? (
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        ) : user.data.roles[0] === "Doctor" ? (
          <Redirect exact from="/" to={PATH.PATIENT_LIST} />
        ) : (
          ""
        )}

        {/* Dashboards */}
        <Route
          path={PATH.MAIN_DASHBOARD}
          component={WEB_PAGES.MAIN_DASHBOARD}
          exact
        />
        <Route
          path={PATH.IMMUNIZATION_DASHBOARD}
          component={WEB_PAGES.IMMUNIZATION_DASHBOARD}
          exact
        />
        <Route
          path={PATH.ANC_DASHBOARD}
          component={WEB_PAGES.ANC_DASHBOARD}
          exact
        />

        {/* Clinics List */}
        <Route
          path={PATH.CLINIC_LIST}
          component={WEB_PAGES.CLINIC_LIST}
          exact
        />
        <Route path={PATH.CLINIC_ADD} component={WEB_PAGES.CLINIC_ADD} exact />
        <Route
          path={PATH.CLINIC_EDIT}
          component={WEB_PAGES.CLINIC_EDIT}
          exact
        />
        <Route
          path={PATH.CLINIC_VIEW}
          component={WEB_PAGES.CLINIC_VIEW}
          exact
        />

        {/* Admin List */}
        <Route path={PATH.ADMIN_LIST} component={WEB_PAGES.ADMIN_LIST} exact />
        <Route path={PATH.ADMIN_ADD} component={WEB_PAGES.ADMIN_ADD} exact />
        <Route path={PATH.ADMIN_EDIT} component={WEB_PAGES.ADMIN_EDIT} exact />
        <Route path={PATH.ADMIN_VIEW} component={WEB_PAGES.ADMIN_VIEW} exact />

        {/* Doctor List */}
        <Route
          path={PATH.DOCTOR_LIST}
          component={WEB_PAGES.DOCTOR_LIST}
          exact
        />
        <Route path={PATH.DOCTOR_ADD} component={WEB_PAGES.DOCTOR_ADD} exact />
        <Route
          path={PATH.DOCTOR_EDIT}
          component={WEB_PAGES.DOCTOR_EDIT}
          exact
        />
        <Route
          path={PATH.DOCTOR_VIEW}
          component={WEB_PAGES.DOCTOR_VIEW}
          exact
        />

        {/* Caregiver List */}
        <Route
          path={PATH.CARE_GIVER_LIST}
          component={WEB_PAGES.CARE_GIVER_LIST}
          exact
        />
        <Route
          path={PATH.CARE_GIVER_ADD}
          component={WEB_PAGES.CARE_GIVER_ADD}
          exact
        />
        <Route
          path={PATH.CARE_GIVER_EDIT}
          component={WEB_PAGES.CARE_GIVER_EDIT}
          exact
        />
        <Route
          path={PATH.CARE_GIVER_VIEW}
          component={WEB_PAGES.CARE_GIVER_VIEW}
          exact
        />

        {/* Child list */}
        <Route path={PATH.CHILD_LIST} component={WEB_PAGES.CHILD_LIST} exact />
        <Route path={PATH.CHILD_EDIT} component={WEB_PAGES.CHILD_EDIT} exact />
        <Route path={PATH.CHILD_VIEW} component={WEB_PAGES.CHILD_VIEW} exact />

        {/* Patient list */}
        <Route
          path={PATH.PATIENT_LIST}
          component={WEB_PAGES.PATIENT_LIST}
          exact
        />
        <Route
          path={PATH.PATIENT_VIEW}
          component={WEB_PAGES.PATIENT_VIEW}
          exact
        />

        {/* Learing Centre Section */}
        <Route
          path={PATH.LEARNING_CENTRE}
          component={WEB_PAGES.LEARNING_CENTRE}
          exact
        />
        <Route
          path={PATH.LEARNING_CENTRE_VIDEO_ADD}
          component={WEB_PAGES.LEARNING_CENTRE_VIDEO_ADD}
          exact
        />
        <Route
          path={PATH.LEARNING_CENTRE_VIDEO_VIEW}
          component={WEB_PAGES.LEARNING_CENTRE_VIDEO_VIEW}
          exact
        />

        <Route path={PATH.SCHEDULE} component={WEB_PAGES.SCHEDULE} exact />
        <Route
          path={PATH.SCHEDULE_ADD}
          component={WEB_PAGES.SCHEDULE_ADD}
          exact
        />
        <Route
          path={PATH.SCHEDULE_EDIT}
          component={WEB_PAGES.SCHEDULE_EDIT}
          exact
        />

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
