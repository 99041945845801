import { request, success, failure, CLINICS_REQUEST } from "./utilities";
import {
  addNewClinicApi,
  getAllClinicsListApi,
  getClinicsForSelectApi,
  getSingleClinicDetailsApi,
} from "../api";

export function getAllClinicsList(filters) {
  return (dispatch) => {
    dispatch(request(CLINICS_REQUEST.GET_CLINICS_LIST_REQUEST));
    getAllClinicsListApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              CLINICS_REQUEST.GET_CLINICS_LIST_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              CLINICS_REQUEST.GET_CLINICS_LIST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(CLINICS_REQUEST.GET_CLINICS_LIST_FAILURE, error.message)
        );
      }
    );
  };
}

export function addNewClinic(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(CLINICS_REQUEST.ADD_NEW_CLINIC_REQUEST));
    addNewClinicApi(data)
      .then(
        (response) => {
          if (response.data.success === true) {
            onSuccess = true;

            dispatch(
              success(
                CLINICS_REQUEST.ADD_NEW_CLINIC_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                CLINICS_REQUEST.ADD_NEW_CLINIC_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(CLINICS_REQUEST.ADD_NEW_CLINIC_FAILURE, error.message)
          );
        }
      )
      .finally(() => {
        if (onSuccess) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function getSingleClinicDetails(clinicId) {
  return (dispatch) => {
    dispatch(request(CLINICS_REQUEST.GET_SINGLE_CLINIC_DETAILS_REQUEST));
    getSingleClinicDetailsApi(clinicId).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              CLINICS_REQUEST.GET_SINGLE_CLINIC_DETAILS_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              CLINICS_REQUEST.GET_SINGLE_CLINIC_DETAILS_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            CLINICS_REQUEST.GET_SINGLE_CLINIC_DETAILS_FAILURE,
            error.message
          )
        );
      }
    );
  };
}

export function getAllClinicsListForSelectAction() {
  return (dispatch) => {
    dispatch(request(CLINICS_REQUEST.GET_CLINICS_LIST_SELECT_REQUEST));
    getClinicsForSelectApi().then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              CLINICS_REQUEST.GET_CLINICS_LIST_SELECT_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              CLINICS_REQUEST.GET_CLINICS_LIST_SELECT_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            CLINICS_REQUEST.GET_CLINICS_LIST_SELECT_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
