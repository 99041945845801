import { GENERIC } from "../actions/utilities";

const INITIAL_STATE = {
  activateUserLoading: false,
  activateUserSuccess: false,
  activateUserFailure: false,
  activateUserError: null,
  activateUser: {},
};

export const genericReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GENERIC.ACTIVATE_USER_REQUEST:
      return {
        ...state,
        activateUserLoading: true,
        activateUserSuccess: false,
        activateUserFailure: false,
        activateUserError: null,
        activateUser: {},
      };
    case GENERIC.ACTIVATE_USER_FAILURE:
      return {
        ...state,
        activateUserLoading: false,
        activateUserSuccess: false,
        activateUserFailure: true,
        activateUserError: action.payload,
      };
    case GENERIC.ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        activateUserLoading: false,
        activateUserSuccess: true,
        activateUserFailure: false,
        activateUserError: null,
        activateUser: action.payload,
      };
    case GENERIC.DEACTIVATE_USER_REQUEST:
      return {
        ...state,
        activateUserLoading: true,
        activateUserSuccess: false,
        activateUserFailure: false,
        activateUserError: null,
        activateUser: {},
      };
    case GENERIC.DEACTIVATE_USER_FAILURE:
      return {
        ...state,
        activateUserLoading: false,
        activateUserSuccess: false,
        activateUserFailure: true,
        activateUserError: action.payload,
      };
    case GENERIC.DEACTIVATE_USER_SUCCESS:
      return {
        ...state,
        activateUserLoading: false,
        activateUserSuccess: true,
        activateUserFailure: false,
        activateUserError: null,
        activateUser: action.payload,
      };

    default:
      return state;
  }
};
