import { DOCTOR_REQUEST } from "../actions/utilities";

const INITIAL_STATE = {
  getDoctorListLoading: false,
  getDoctorListSuccess: false,
  getDoctorListFailure: false,
  getDoctorListError: null,
  doctorList: [],
  doctorListCount: 0,

  addEditDoctorLoading: false,
  addEditDoctorSuccess: false,
  addEditDoctorFailure: false,
  addEditDoctorError: null,

  getSingleDoctorDetailsLoading: false,
  getSingleDoctorDetailsSuccess: false,
  getSingleDoctorDetailsFailure: false,
  getSingleDoctorDetailsError: null,
  singleDoctorDetails: null,

  setDoctorActiveLoading: false,
  setDoctorActiveSuccess: false,
  setDoctorActiveFailure: false,
  setDoctorActiveError: null,

  getDoctorsSelectLoading: false,
  getDoctorsSelectSuccess: false,
  getDoctorsSelectFailure: false,
  getDoctorsSelectError: null,
  doctorsSelect: [],
};

export const doctorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DOCTOR_REQUEST.GET_DOCTOR_LIST_REQUEST:
      return {
        ...state,
        getDoctorListLoading: true,
        getDoctorListSuccess: false,
        getDoctorListFailure: false,
        getDoctorListError: null,
        doctorList: [],
        doctorListCount: 0,
      };
    case DOCTOR_REQUEST.GET_DOCTOR_LIST_FAILURE:
      return {
        ...state,
        getDoctorListLoading: false,
        getDoctorListSuccess: false,
        getDoctorListFailure: true,
        getDoctorListError: action.payload,
      };
    case DOCTOR_REQUEST.GET_DOCTOR_LIST_SUCCESS:
      return {
        ...state,
        getDoctorListLoading: false,
        getDoctorListSuccess: true,
        getDoctorListFailure: false,
        getDoctorListError: null,
        doctorList: action.payload,
        doctorListCount: action.info.count,
      };
    case DOCTOR_REQUEST.ADD_EDIT_DOCTOR_REQUEST:
      return {
        ...state,
        addEditDoctorLoading: true,
        addEditDoctorSuccess: false,
        addEditDoctorFailure: false,
        addEditDoctorError: null,
      };
    case DOCTOR_REQUEST.ADD_EDIT_DOCTOR_FAILURE:
      return {
        ...state,
        addEditDoctorLoading: false,
        addEditDoctorSuccess: false,
        addEditDoctorFailure: true,
        addEditDoctorError: action.payload,
      };
    case DOCTOR_REQUEST.ADD_EDIT_DOCTOR_SUCCESS:
      return {
        ...state,
        addEditDoctorLoading: false,
        addEditDoctorSuccess: true,
        addEditDoctorFailure: false,
        addEditDoctorError: null,
      };
    case DOCTOR_REQUEST.GET_SINGLE_DOCTOR_DETAILS_REQUEST:
      return {
        ...state,
        getSingleDoctorDetailsLoading: true,
        getSingleDoctorDetailsSuccess: false,
        getSingleDoctorDetailsFailure: false,
        getSingleDoctorDetailsError: null,
        singleDoctorDetails: null,
      };
    case DOCTOR_REQUEST.GET_SINGLE_DOCTOR_DETAILS_FAILURE:
      return {
        ...state,
        getSingleDoctorDetailsLoading: false,
        getSingleDoctorDetailsSuccess: false,
        getSingleDoctorDetailsFailure: true,
        getSingleDoctorDetailsError: action.payload,
      };
    case DOCTOR_REQUEST.GET_SINGLE_DOCTOR_DETAILS_SUCCESS:
      return {
        ...state,
        getSingleDoctorDetailsLoading: false,
        getSingleDoctorDetailsSuccess: true,
        getSingleDoctorDetailsFailure: false,
        getSingleDoctorDetailsError: null,
        singleDoctorDetails: action.payload,
      };
    case DOCTOR_REQUEST.SET_DOCTOR_ACTIVE_REQUEST:
      return {
        ...state,
        setDoctorActiveLoading: true,
        setDoctorActiveSuccess: false,
        setDoctorActiveFailure: false,
        setDoctorActiveError: null,
      };
    case DOCTOR_REQUEST.SET_DOCTOR_ACTIVE_FAILURE:
      return {
        ...state,
        setDoctorActiveLoading: false,
        setDoctorActiveSuccess: false,
        setDoctorActiveFailure: true,
        setDoctorActiveError: action.payload,
      };
    case DOCTOR_REQUEST.SET_DOCTOR_ACTIVE_SUCCESS:
      let updatedList = state.doctorList;
      for (let i = 0; i < updatedList.length; i++) {
        if (updatedList[i].id === action.payload.userId) {
          updatedList[i].isActive = action.payload.isActive;
        }
      }
      return {
        ...state,
        setDoctorActiveLoading: false,
        setDoctorActiveSuccess: true,
        setDoctorActiveFailure: false,
        setDoctorActiveError: null,
        doctorList: updatedList,
      };
    case DOCTOR_REQUEST.GET_DOCTORS_SELECT_REQUEST:
      return {
        ...state,
        getDoctorsSelectLoading: true,
        getDoctorsSelectSuccess: false,
        getDoctorsSelectFailure: false,
        getDoctorsSelectError: null,
        doctorsSelect: [],
      };
    case DOCTOR_REQUEST.GET_DOCTORS_SELECT_FAILURE:
      return {
        ...state,
        getDoctorsSelectLoading: false,
        getDoctorsSelectSuccess: false,
        getDoctorsSelectFailure: true,
        getDoctorsSelectError: action.payload,
      };
    case DOCTOR_REQUEST.GET_DOCTORS_SELECT_SUCCESS:
      return {
        ...state,
        getDoctorsSelectLoading: false,
        getDoctorsSelectSuccess: true,
        getDoctorsSelectFailure: false,
        getDoctorsSelectError: null,
        doctorsSelect: action.payload,
      };

    default:
      return state;
  }
};
