import axios from "axios";
import { API_PATH } from "../config";

/**
 * Get Patient List
 *
 * @param {Object} filters
 * @return {*} Promise
 */
export function getPatientListApi(filters) {
    return axios.get(API_PATH.PATIENT.getPatientList, {
        params: filters,
    });
}

/**
 * Get Single Patient Details
 *
 * @param {Object} patientId
 * @return {*} Promise
 */
export function getSinglePatientDetailApi(patientId) {
    return axios.get(`${API_PATH.PATIENT.getSinglePatient}?Id=${patientId}`);
}