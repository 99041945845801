import { request, success, failure, PATIENT_REQUEST } from "./utilities";
import {
    getPatientListApi, getSinglePatientDetailApi,
} from "../api";

export function getPatientList(filters) {
    return (dispatch) => {
        dispatch(request(PATIENT_REQUEST.GET_PATIENT_LIST_REQUEST));
        getPatientListApi(filters).then(
            (response) => {
                if (response.data.success === true) {
                    dispatch(
                        success(PATIENT_REQUEST.GET_PATIENT_LIST_SUCCESS, response.data.data.patients, {
                            count: response.data.totalRecords,
                        })
                    );
                } else {
                    dispatch(
                        failure(PATIENT_REQUEST.GET_PATIENT_LIST_FAILURE, response.data.message)
                    );
                }
            },
            (error) => {
                dispatch(failure(PATIENT_REQUEST.GET_PATIENT_LIST_FAILURE, error.message));
            }
        );
    };
}

export function getSinglePatientDetail(filters) {

    return (dispatch) => {
        dispatch(request(PATIENT_REQUEST.GET_SINGLE_PATIENT_DETAIL_REQUEST));
        getSinglePatientDetailApi(filters).then(
            (response) => {
                if (response.data.success === true) {
                    dispatch(
                        success(PATIENT_REQUEST.GET_SINGLE_PATIENT_DETAIL_SUCCESS, response.data.data)
                    );
                } else {
                    dispatch(
                        failure(PATIENT_REQUEST.GET_SINGLE_PATIENT_DETAIL_FAILURE, response.data.message)
                    );
                }
            },
            (error) => {
                dispatch(failure(PATIENT_REQUEST.GET_SINGLE_PATIENT_DETAIL_FAILURE, error.message));
            }
        );
    };
}

export function getPatientChildsList(filters) {
    return (dispatch) => {
        dispatch(request(PATIENT_REQUEST.GET_PATIENT_CHILDS_LIST_REQUEST));
        getSinglePatientDetailApi(filters).then(
            (response) => {
                if (response.data.success === true) {
                    dispatch(
                        success(PATIENT_REQUEST.GET_PATIENT_CHILDS_LIST_SUCCESS, response.data.data.childs)
                    );
                } else {
                    dispatch(
                        failure(PATIENT_REQUEST.GET_PATIENT_CHILDS_LIST_FAILURE, response.data.message)
                    );
                }
            },
            (error) => {
                dispatch(failure(PATIENT_REQUEST.GET_PATIENT_CHILDS_LIST_FAILURE, error.message));
            }
        );
    };
}