import { CLINICS_REQUEST } from "../actions/utilities";

const INITIAL_STATE = {
  getClinicListLoading: false,
  getClinicListSuccess: false,
  getClinicListFailure: false,
  getClinicListError: null,
  clinicsList: [],
  clinicsListCount: 0,

  addNewClinicLoading: false,
  addNewClinicSuccess: false,
  addNewClinicFailure: false,
  addNewClinicError: null,

  getSingleClinicDetailsLoading: false,
  getSingleClinicDetailsSuccess: false,
  getSingleClinicDetailsFailure: false,
  getSingleClinicDetailsError: null,
  singleClinicDetails: false,

  getClinicListSelectLoading: false,
  getClinicListSelectSuccess: false,
  getClinicListSelectFailure: false,
  getClinicListSelectError: null,
  clinicsListSelect: [],
};

export const clinicsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLINICS_REQUEST.GET_CLINICS_LIST_REQUEST:
      return {
        ...state,
        getClinicListLoading: true,
        getClinicListSuccess: false,
        getClinicListFailure: false,
        getClinicListError: null,
        clinicsList: [],
        clinicsListCount: 0,
      };
    case CLINICS_REQUEST.GET_CLINICS_LIST_FAILURE:
      return {
        ...state,
        getClinicListLoading: false,
        getClinicListSuccess: false,
        getClinicListFailure: true,
        getClinicListError: action.payload,
      };
    case CLINICS_REQUEST.GET_CLINICS_LIST_SUCCESS:
      return {
        ...state,
        getClinicListLoading: false,
        getClinicListSuccess: true,
        getClinicListFailure: false,
        getClinicListError: null,
        clinicsList: action.payload,
        clinicsListCount: action.info.count,
      };
    case CLINICS_REQUEST.ADD_NEW_CLINIC_REQUEST:
      return {
        ...state,
        addNewClinicLoading: true,
        addNewClinicSuccess: false,
        addNewClinicFailure: false,
        addNewClinicError: null,
      };
    case CLINICS_REQUEST.ADD_NEW_CLINIC_FAILURE:
      return {
        ...state,
        addNewClinicLoading: false,
        addNewClinicSuccess: false,
        addNewClinicFailure: true,
        addNewClinicError: action.payload,
      };
    case CLINICS_REQUEST.ADD_NEW_CLINIC_SUCCESS:
      return {
        ...state,
        addNewClinicLoading: false,
        addNewClinicSuccess: true,
        addNewClinicFailure: false,
        addNewClinicError: null,
      };
    case CLINICS_REQUEST.GET_SINGLE_CLINIC_DETAILS_REQUEST:
      return {
        ...state,
        getSingleClinicDetailsLoading: true,
        getSingleClinicDetailsSuccess: false,
        getSingleClinicDetailsFailure: false,
        getSingleClinicDetailsError: null,
        singleClinicDetails: false,
      };
    case CLINICS_REQUEST.GET_SINGLE_CLINIC_DETAILS_FAILURE:
      return {
        ...state,
        getSingleClinicDetailsLoading: false,
        getSingleClinicDetailsSuccess: false,
        getSingleClinicDetailsFailure: true,
        getSingleClinicDetailsError: action.payload,
      };
    case CLINICS_REQUEST.GET_SINGLE_CLINIC_DETAILS_SUCCESS:
      return {
        ...state,
        getSingleClinicDetailsLoading: false,
        getSingleClinicDetailsSuccess: true,
        getSingleClinicDetailsFailure: false,
        getSingleClinicDetailsError: null,
        singleClinicDetails: action.payload,
      };
    case CLINICS_REQUEST.GET_CLINICS_LIST_SELECT_REQUEST:
      return {
        ...state,
        getClinicListSelectLoading: true,
        getClinicListSelectSuccess: false,
        getClinicListSelectFailure: false,
        getClinicListSelectError: null,
        clinicsList: [],
      };
    case CLINICS_REQUEST.GET_CLINICS_LIST_SELECT_FAILURE:
      return {
        ...state,
        getClinicListSelectLoading: false,
        getClinicListSelectSuccess: false,
        getClinicListSelectFailure: true,
        getClinicListSelectError: action.payload,
      };
    case CLINICS_REQUEST.GET_CLINICS_LIST_SELECT_SUCCESS:
      return {
        ...state,
        getClinicListSelectLoading: false,
        getClinicListSelectSuccess: true,
        getClinicListSelectFailure: false,
        getClinicListSelectError: null,
        clinicsListSelect: action.payload,
      };

    default:
      return state;
  }
};
