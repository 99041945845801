export const dashboardOption = [
  { value: 1, label: "Global Dashboard" },
  { value: 2, label: "Immunization" },
  { value: 3, label: "Antenatal Care" },
];

export const OPTION = {
  AGE: [
    { value: "", label: "All Ages" },
    { value: 1, label: "< 1" },
    { value: 2, label: "< 2" },
    { value: 3, label: "< 3" },
    { value: 4, label: "< 4" },
    { value: 5, label: "< 5" },
  ],

  GENDER: [
    { value: "", label: "All Genders" },
    { value: 0, label: "Male" },
    { value: 1, label: "Female" },
  ],

  MARITAL_STATUS: [
    { value: 0, label: "Single" },
    { value: 1, label: "Married" },
    { value: 2, label: "Divorced" },
    { value: 3, label: "Separated" },
    { value: 4, label: "Widowed" },
  ],

  MONTH: [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ],

  YEAR: [
    { value: 2020, label: 2020 },
    { value: 2021, label: 2021 },
    { value: 2022, label: 2022 },
    { value: 2023, label: 2023 },
    { value: 2024, label: 2024 },
    { value: 2025, label: 2025 },
    { value: 2026, label: 2026 },
    { value: 2027, label: 2027 },
    { value: 2028, label: 2028 },
    { value: 2029, label: 2029 },
    { value: 2030, label: 2030 },
  ],

  CLINIC: [
    { value: 1, label: "Kalkaal MCH" },
    { value: 2, label: "Shafici MCH" },
    { value: 3, label: "Gargar MCH" },
    { value: 4, label: "Waberi MCH" },
  ],

  CHART_TYPE: [
    { value: 1, label: "Yearly" },
    { value: 2, label: "Monthly" },
  ],

  LANGUAGES: [
    { value: 1, label: "Somali" },
    { value: 2, label: "English" },
  ],
};
