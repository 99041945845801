import { IMMUNIZATION_DASHBOARD } from "../actions/utilities";

const INITIAL_STATE = {
  getImzStatsLoading: false,
  getImzStatsSuccess: false,
  getImzStatsFailure: false,
  getImzStatsError: null,
  imzDashboardStats: [],

  getImmunizationChartLoading: false,
  getImmunizationChartSuccess: false,
  getImmunizationChartFailure: false,
  getImmunizationChartError: null,
  immunizationChartData: [],

  getCompletedImmunizationLoading: false,
  getCompletedImmunizationSuccess: false,
  getCompletedImmunizationFailure: false,
  getCompletedImmunizationError: null,
  completedImmunizationData: [],
  completedImmunizationCount: 0,

  getIncompletedImmunizationLoading: false,
  getIncompletedImmunizationSuccess: false,
  getIncompletedImmunizationFailure: false,
  getIncompletedImmunizationError: null,
  incompletedImmunizationData: [],
  incompletedImmunizationCount: 0,

  getMissedImmunizationLoading: false,
  getMissedImmunizationSuccess: false,
  getMissedImmunizationFailure: false,
  getMissedImmunizationError: null,
  missedImmunizationData: [],
  missedImmunizationCount: 0,

  getChildImmunizationLoading: false,
  getChildImmunizationSuccess: false,
  getChildImmunizationFailure: false,
  getChildImmunizationError: null,
  childImmunizationData: [],
  childImmunizationCount: 0,

  getPatientVaccinationLoading: false,
  getPatientVaccinationSuccess: false,
  getPatientVaccinationFailure: false,
  getPatientVaccinationError: null,
  patientVaccinationData: [],
  patientVaccinationCount: 0,

  exportStatsExcelLoading: false,
  exportStatsExcelSuccess: false,
  exportStatsExcelFailure: false,
  exportStatsExcelError: null,
  exportStatsExcelData: null,

  exportMissedImmunizationLoading: false,
  exportMissedImmunizationSuccess: false,
  exportMissedImmunizationFailure: false,
  exportMissedImmunizationError: null,
  exportMissedImmunizationData: null,

  exportChildImmunizationLoading: false,
  exportChildImmunizationSuccess: false,
  exportChildImmunizationFailure: false,
  exportChildImmunizationError: null,
  exportChildImmunizationData: null,
};

export const immunizationDashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IMMUNIZATION_DASHBOARD.GET_IMZ_STATS_REQUEST:
      return {
        ...state,
        getImzStatsLoading: true,
        getImzStatsSuccess: false,
        getImzStatsFailure: false,
        getImzStatsError: null,
      };
    case IMMUNIZATION_DASHBOARD.GET_IMZ_STATS_FAILURE:
      return {
        ...state,
        getImzStatsLoading: false,
        getImzStatsSuccess: false,
        getImzStatsFailure: true,
        getImzStatsError: action.payload,
      };
    case IMMUNIZATION_DASHBOARD.GET_IMZ_STATS_SUCCESS:
      return {
        ...state,
        getImzStatsLoading: false,
        getImzStatsSuccess: true,
        getImzStatsFailure: false,
        getImzStatsError: null,
        imzDashboardStats: action.payload,
      };
    case IMMUNIZATION_DASHBOARD.GET_IMMUNIZATION_CHART_REQUEST:
      return {
        ...state,
        getImmunizationChartLoading: true,
        getImmunizationChartSuccess: false,
        getImmunizationChartFailure: false,
        getImmunizationChartError: null,
        immunizationChartData: [],
      };
    case IMMUNIZATION_DASHBOARD.GET_IMMUNIZATION_CHART_FAILURE:
      return {
        ...state,
        getImmunizationChartLoading: false,
        getImmunizationChartSuccess: false,
        getImmunizationChartFailure: true,
        getImmunizationChartError: action.payload,
        immunizationChartData: [],
      };
    case IMMUNIZATION_DASHBOARD.GET_IMMUNIZATION_CHART_SUCCESS:
      return {
        ...state,
        getImmunizationChartLoading: false,
        getImmunizationChartSuccess: true,
        getImmunizationChartFailure: false,
        getImmunizationChartError: null,
        immunizationChartData: action.payload,
      };

    case IMMUNIZATION_DASHBOARD.GET_COMPLETED_IMMUNIZATION_REQUEST:
      return {
        ...state,
        getCompletedImmunizationLoading: true,
        getCompletedImmunizationSuccess: false,
        getCompletedImmunizationFailure: false,
        getCompletedImmunizationError: null,
      };
    case IMMUNIZATION_DASHBOARD.GET_COMPLETED_IMMUNIZATION_FAILURE:
      return {
        ...state,
        getCompletedImmunizationLoading: false,
        getCompletedImmunizationSuccess: false,
        getCompletedImmunizationFailure: true,
        getCompletedImmunizationError: action.payload,
      };
    case IMMUNIZATION_DASHBOARD.GET_COMPLETED_IMMUNIZATION_SUCCESS:
      return {
        ...state,
        getCompletedImmunizationLoading: false,
        getCompletedImmunizationSuccess: true,
        getCompletedImmunizationFailure: false,
        getCompletedImmunizationError: null,
        completedImmunizationData: action.payload,
        completedImmunizationCount: action.info.count,
      };
    case IMMUNIZATION_DASHBOARD.GET_INCOMPLETED_IMMUNIZATION_REQUEST:
      return {
        ...state,
        getIncompletedImmunizationLoading: true,
        getIncompletedImmunizationSuccess: false,
        getIncompletedImmunizationFailure: false,
        getIncompletedImmunizationError: null,
      };
    case IMMUNIZATION_DASHBOARD.GET_INCOMPLETED_IMMUNIZATION_FAILURE:
      return {
        ...state,
        getIncompletedImmunizationLoading: false,
        getIncompletedImmunizationSuccess: false,
        getIncompletedImmunizationFailure: true,
        getIncompletedImmunizationError: action.payload,
      };
    case IMMUNIZATION_DASHBOARD.GET_INCOMPLETED_IMMUNIZATION_SUCCESS:
      return {
        ...state,
        getIncompletedImmunizationLoading: false,
        getIncompletedImmunizationSuccess: true,
        getIncompletedImmunizationFailure: false,
        getIncompletedImmunizationError: null,
        incompletedImmunizationData: action.payload,
        incompletedImmunizationCount: action.info.count,
      };
    case IMMUNIZATION_DASHBOARD.GET_MISSED_IMMUNIZATION_REQUEST:
      return {
        ...state,
        getMissedImmunizationLoading: true,
        getMissedImmunizationSuccess: false,
        getMissedImmunizationFailure: false,
        getMissedImmunizationError: null,
      };
    case IMMUNIZATION_DASHBOARD.GET_MISSED_IMMUNIZATION_FAILURE:
      return {
        ...state,
        getMissedImmunizationLoading: false,
        getMissedImmunizationSuccess: false,
        getMissedImmunizationFailure: true,
        getMissedImmunizationError: action.payload,
      };
    case IMMUNIZATION_DASHBOARD.GET_MISSED_IMMUNIZATION_SUCCESS:
      return {
        ...state,
        getMissedImmunizationLoading: false,
        getMissedImmunizationSuccess: true,
        getMissedImmunizationFailure: false,
        getMissedImmunizationError: null,
        missedImmunizationData: action.payload,
        missedImmunizationCount: action.info.count,
      };
    case IMMUNIZATION_DASHBOARD.GET_CHILD_IMMUNIZATION_REQUEST:
      return {
        ...state,
        getChildImmunizationLoading: true,
        getChildImmunizationSuccess: false,
        getChildImmunizationFailure: false,
        getChildImmunizationError: null,
      };
    case IMMUNIZATION_DASHBOARD.GET_CHILD_IMMUNIZATION_FAILURE:
      return {
        ...state,
        getChildImmunizationLoading: false,
        getChildImmunizationSuccess: false,
        getChildImmunizationFailure: true,
        getChildImmunizationError: action.payload,
      };
    case IMMUNIZATION_DASHBOARD.GET_CHILD_IMMUNIZATION_SUCCESS:
      return {
        ...state,
        getChildImmunizationLoading: false,
        getChildImmunizationSuccess: true,
        getChildImmunizationFailure: false,
        getChildImmunizationError: null,
        childImmunizationData: action.payload,
        childImmunizationCount: action.info.count,
      };

    case IMMUNIZATION_DASHBOARD.GET_PATIENT_VACCINATION_REQUEST:
      return {
        ...state,
        getPatientVaccinationLoading: true,
        getPatientVaccinationSuccess: false,
        getPatientVaccinationFailure: false,
        getPatientVaccinationError: null,
      };
    case IMMUNIZATION_DASHBOARD.GET_PATIENT_VACCINATION_FAILURE:
      return {
        ...state,
        getPatientVaccinationLoading: false,
        getPatientVaccinationSuccess: false,
        getPatientVaccinationFailure: true,
        getPatientVaccinationError: action.payload,
      };
    case IMMUNIZATION_DASHBOARD.GET_PATIENT_VACCINATION_SUCCESS:
      return {
        ...state,
        getPatientVaccinationLoading: false,
        getPatientVaccinationSuccess: true,
        getPatientVaccinationFailure: false,
        getPatientVaccinationError: null,
        patientVaccinationData: action.payload,
        patientVaccinationCount: action.info.count,
      };

    case IMMUNIZATION_DASHBOARD.EXPORT_STATS_EXCEL_REQUEST:
      return {
        ...state,
        exportStatsExcelLoading: true,
        exportStatsExcelSuccess: false,
        exportStatsExcelFailure: false,
        exportStatsExcelError: null,
      };
    case IMMUNIZATION_DASHBOARD.EXPORT_STATS_EXCEL_FAILURE:
      return {
        ...state,
        exportStatsExcelLoading: false,
        exportStatsExcelSuccess: false,
        exportStatsExcelFailure: true,
        exportStatsExcelError: action.payload,
      };
    case IMMUNIZATION_DASHBOARD.EXPORT_STATS_EXCEL_SUCCESS:
      return {
        ...state,
        exportStatsExcelLoading: false,
        exportStatsExcelSuccess: true,
        exportStatsExcelFailure: false,
        exportStatsExcelError: null,
        exportStatsExcelData: action.payload,
      };

    case IMMUNIZATION_DASHBOARD.EXPORT_MISSED_IMMUNIZATION_REQUEST:
      return {
        ...state,
        exportMissedImmunizationLoading: true,
        exportMissedImmunizationSuccess: false,
        exportMissedImmunizationFailure: false,
        exportMissedImmunizationError: null,
      };
    case IMMUNIZATION_DASHBOARD.EXPORT_MISSED_IMMUNIZATION_FAILURE:
      return {
        ...state,
        exportMissedImmunizationLoading: false,
        exportMissedImmunizationSuccess: false,
        exportMissedImmunizationFailure: true,
        exportMissedImmunizationError: action.payload,
      };
    case IMMUNIZATION_DASHBOARD.EXPORT_MISSED_IMMUNIZATION_SUCCESS:
      return {
        ...state,
        exportMissedImmunizationLoading: false,
        exportMissedImmunizationSuccess: true,
        exportMissedImmunizationFailure: false,
        exportMissedImmunizationError: null,
        exportMissedImmunizationData: action.payload,
      };
    case IMMUNIZATION_DASHBOARD.EXPORT_CHILD_IMMUNIZATION_REQUEST:
      return {
        ...state,
        exportChildImmunizationLoading: true,
        exportChildImmunizationSuccess: false,
        exportChildImmunizationFailure: false,
        exportChildImmunizationError: null,
      };
    case IMMUNIZATION_DASHBOARD.EXPORT_CHILD_IMMUNIZATION_FAILURE:
      return {
        ...state,
        exportChildImmunizationLoading: false,
        exportChildImmunizationSuccess: false,
        exportChildImmunizationFailure: true,
        exportChildImmunizationError: action.payload,
      };
    case IMMUNIZATION_DASHBOARD.EXPORT_CHILD_IMMUNIZATION_SUCCESS:
      return {
        ...state,
        exportChildImmunizationLoading: false,
        exportChildImmunizationSuccess: true,
        exportChildImmunizationFailure: false,
        exportChildImmunizationError: null,
        exportChildImmunizationData: action.payload,
      };

    default:
      return state;
  }
};
