import axios from "axios";
import { API_PATH } from "../config";

/**
 * Activate User Api
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function activateUserApi(data) {
  return axios.patch(API_PATH.GENERIC.activateUser, data);
}

/**
 * Deactivate User Api
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function deactivateUserApi(data) {
  return axios.patch(API_PATH.GENERIC.deactivateUser, data);
}
