import axios from "axios";
import { API_PATH } from "../config";

/**
 * Get All Admin List
 *
 * @param {Object} filters
 * @return {*} Promise
 */
export function getAllAdminListApi(filters) {
    return axios.get(API_PATH.ADMIN.getAllAdminList, {
        params: filters,
    });
}
/**
 * Get Single Clinics Detail
 *
 * @param {Object} id
 * @return {*} Promise
 */
export function getSingleAdminDetailsApi(Id) {
    return axios.get(`${API_PATH.ADMIN.getSingleAdmin}?Id=${Id}`);
}
/**
 * Set Admin Active
 *
 * @param {Object} id
 * @return {*} Promise
 */
export function setAdminActiveApi(rec) {
    return axios.post(`${API_PATH.ADMIN.setAdminActive}`, rec, {
    });
}

/**
 * Set Admin Active
 *
 * @param {Object} id
 * @return {*} Promise
 */
export function addEditAdminApi(rec) {
    return axios.post(`${API_PATH.ADMIN.addEditAdmin}`, rec, {});
}

/**
 * Get All Admin Clinic List
 *
 * @param {Object} id
 * @return {*} Promise
 */
export function getAdminClinicListApi(id) {
    return axios.get(`${API_PATH.ADMIN.getAdminClinicList}?AdminId=${id}`);
}

/**
 * Get Assign Admin Clinic
 *
 * @param {Object} id
 * @return {*} Promise
 */
export function assignedClinicToAdminApi(data) {
    return axios.post(`${API_PATH.ADMIN.assignClinics}`, data);
}

